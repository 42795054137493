import Vue from "vue";
import moment from "moment";

export function formatDateTime(value, format) {
  if (!value) {
    return "";
  }

  if (!format) {
    format = "DD MMMM YYYY, H:mm";
  }

  const formattedDate = moment(value.toString());

  if (!formattedDate.isValid()) {
    console.warn(`Provided date '${value}' can not be parsed by moment: `, value);
    return value;
  }

  return formattedDate.local().format(format);
}

Vue.filter("date", formatDateTime);
