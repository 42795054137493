<template>
  <v-dialog v-model="dialog" persistent :max-width="options.width">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-card>
      <v-card-title class="headline">{{ options.header }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          data-cy="no"
          v-if="options.yesNo"
          color="secondary"
          outlined
          @click="no()"
          >{{ options.noText || "No" }}</v-btn
        >
        <v-btn data-cy="yes" v-if="options.yesNo" color="primary" @click="ok()">{{
          options.yesText || "Yes"
        }}</v-btn>
        <v-btn data-cy="ok" v-if="!options.yesNo" color="primary" @click="ok()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "alert",
  data() {
    return {
      dialog: false,
      message: null,
      title: null,
      options: {},
    };
  },
  methods: {
    initialize() {
      this.options = {
        width: 290,
      };
    },
    open(message, options) {
      this.initialize();
      this.dialog = true;
      this.message = message;
      if (options) {
        this.options = options;
      }
    },
    ok: function () {
      this.dialog = false;
      if (this.options.callback) {
        this.options.callback({ accepted: true });
      }
    },
    no: function () {
      this.dialog = false;
      if (this.options.callback) {
        this.options.callback({ accepted: false });
      }
    },
  },
};
</script>
