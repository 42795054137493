<template>
  <div class="wrapper pl-4" v-if="crumbs && crumbs.length > 0">
    <v-breadcrumbs :items="crumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.to">
          <div
            v-if="item.text.toLowerCase() === constants.friss"
            style="display: flex; align-items: center"
          >
            <v-icon class="mr-2" small>$frissIcon</v-icon> DataService
          </div>
          <template v-else
            ><span class="breadcrumb-link">{{ item.text }}</span></template
          >
        </v-breadcrumbs-item>
      </template></v-breadcrumbs
    >
  </div>
</template>

<style scoped>
.wrapper {
  height: fit-content;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.breadcrumb-link {
  color: var(--marine-regular);
  text-decoration: underline;
}
.breadcrumb-link:hover {
  text-decoration: none;
}

.v-image:hover {
  transform: scale(0.95);
}
</style>

<script>
import constants from "@/services/constants";
export default {
  data() {
    return {
      constants,
    };
  },
  computed: {
    crumbs: function () {
      var parents = this.$route.matched[0]?.meta.parents;
      return parents?.map((parent) => {
        var route = this.$router.resolve({
          name: parent,
          params: this.$route.params,
        }).route;
        return {
          to: route.fullPath + (route.meta.query ? route.meta.query() : ""),
          text: route.meta.breadCrumb(this.$route),
        };
      });
    },
  },
};
</script>
