<template>
  <v-app id="app">
    <appheader></appheader>
    <progressbar></progressbar>
    <navbar></navbar>

    <v-main>
      <breadcrumb></breadcrumb>
      <router-view></router-view>
    </v-main>

    <alert ref="alert"></alert>
    <snackbar></snackbar>
  </v-app>
</template>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "./assets/style.scss";
@import "./assets/fonts.css";
@import "./assets/components.scss";

:root {
  --orange-darker: #{$orange-darker};
  --orange-regular: #{$orange-regular};
  --orange-medium: #{$orange-medium};
  --orange-light: #{$orange-light};
  --orange-disabled: #{$orange-disabled};

  --marine-regular: #{$marine-regular};
  --marine-medium: #{$marine-medium};
  --marine-light: #{$marine-light};
  --marine-disabled: #{$marine-disabled};

  --aqua-darker: #{$aqua-darker};
  --aqua-regular: #{$aqua-regular};
  --aqua-medium: #{$aqua-medium};
  --aqua-light: #{$aqua-light};
  --aqua-disabled: #{$aqua-disabled};

  --almost-white: #{$almost-white};

  --notify-darker: #{$notify-darker};
  --notify-regular: #{$notify-regular};
  --notify-medium: #{$notify-medium};
  --notify-light: #{$notify-light};
  --notify-disabled: #{$notify-disabled};

  --red-regular: #{$red-regular};
  --red-light: #{$red-light};

  --amber-regular: #{$amber-regular};
  --amber-light: #{$amber-light};

  --green-regular: #{$green-regular};
  --green-light: #{$green-light};

  --blue-regular: #{$blue-regular};
  --blue-light: #{$blue-light};

  --modal-backdrop: #{$modal-backdrop};
}
</style>

<script>
/* eslint-disable */
import appheader from "@/components/header.vue";
import progressbar from "@/components/progress.bar.vue";
import snackbar from "@/components/snack.bar.vue";
import alert from "@/components/alert.dialog.vue";
import breadcrumb from "@/components/breadcrumb.vue";
import navbar from "@/components/nav.bar.vue";

export default {
  components: {
    appheader,
    progressbar,
    snackbar,
    alert,
    breadcrumb,
    navbar,
  },
  mounted() {
    this.$root.$alert = this.$refs.alert.open;
  },
};
</script>
