<template>
  <v-menu open-on-hover offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        style="margin-bottom: 10px"
        @click="$emit('icon-clicked')"
        icon
        v-bind="attrs"
        v-on="on"
        :data-cy="dataCy"
        :color="isActive(path) ? 'primary' : ''"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>

    <v-list style="padding: 0" dense>
      <slot></slot>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "menuComp",
  props: ["icon", "path", "dataCy"],
  methods: {
    isActive: function (paths) {
      for (const path of paths) {
        if (path.endsWith("*")) {
          if (this.$route.path.startsWith(path.split("*")[0])) {
            return true;
          }
        } else {
          if (this.$route.path === path) {
            return true;
          }
        }
      }

      return false;
    },
  },
};
</script>
