<template>
  <v-card>
    <v-card-text>
      <h4>Custom authentication</h4>
      <v-row>
        <v-col md="6" v-for="vm in viewModels" :key="vm.item.key">
          <encrypted-input
            v-model="vm.item.value"
            :label="vm.item.key"
            :data-cy="vm.item.key"
            :readonly="readonly"
          ></encrypted-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomTokenCredentialsDto } from "@/datahub-api";

@Component
export default class CustomTemplateAuthComponent extends Vue {
  @Prop() model: CustomTokenCredentialsDto;
  @Prop({ default: false }) readonly: boolean;

  get viewModels() {
    return this.model.items!.map((i) => {
      return { item: i, show: false };
    });
  }
}
</script>
