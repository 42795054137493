<template>
  <v-card style="margin: 5px 0">
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <td width="30%">DateFormat</td>
              <td width="25%">Type</td>
              <td width="10%">Operator</td>
              <td width="30%">Value</td>
              <td width="5%">
                <v-icon :disabled="wheres.length > 0" @click="add()" data-cy="isvalidadd"
                  >mdi-plus</v-icon
                >
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in wheres" :key="i">
              <td>
                <tooltip message="This is used to parse the datetime values from the vendor response. The most used formats are yyyy-MM-dd, dd-MM-yyyy, MM-dd-yyyy">
                  <v-text-field v-if="isDatetimeField(item.type)"
                                      v-model="item.dateFormat"
                                      data-cy="isvaliddateformat">
                  </v-text-field>
                </tooltip>
              </td>
              <td>
                <v-select
                  :items="$lookupService.types"
                  v-model="item.type"
                  data-cy="isvalidtype"
                ></v-select>
              </td>
              <td>
                <v-select
                  :items="$lookupService.getOperators(item.type)"
                  v-model="item.operator"
                  data-cy="isvalidoperator"
                ></v-select>
              </td>
              <td>
                <v-text-field v-model="item.value" data-cy="isvalidvalue"></v-text-field>
              </td>
              <td>
                <tooltip message="Delete">
                  <friss-icon
                    data-cy="paramdelete"
                    v-if="$permissions.canModifyDataSource"
                    @click="remove(i)"
                    name="delete"
                  ></friss-icon>
                </tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { Where, FieldType } from "@/datahub-api";

@Component
export default class ExpressionAggregationWhereComponent extends Vue {
  @Prop() wheres: Where[];
  isDatetimeField(type: FieldType) {
    return type === FieldType.DatetimeType;
  }

  add() {
    let item = {
      path: "Value",
      type: 0,
      operator: 0,
      value: "",
      condition: 0,
      dateFormat: ""
    };
    this.wheres.push(item);
  }

  remove(index: number) {
    this.wheres.splice(index, 1);
  }
}
</script>
