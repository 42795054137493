interface RuntimeConfig {
  authority: string;
  clientId: string;
  dataservice: string;
  datahub: string;
  stage: string;
}

const runtimeconfig: RuntimeConfig = JSON.parse(window.__RUNTIMECONFIG__);

if (process.env.VUE_APP_AUTHORITY) {
  runtimeconfig.authority = process.env.VUE_APP_AUTHORITY;
}
if (process.env.VUE_APP_CLIENTID) {
  runtimeconfig.clientId = process.env.VUE_APP_CLIENTID;
}

if (process.env.VUE_APP_API) {
  runtimeconfig.datahub = process.env.VUE_APP_API;
}

if (process.env.VUE_APP_API_DATA_SERVICE) {
  runtimeconfig.dataservice = process.env.VUE_APP_API_DATA_SERVICE;
}

if (process.env.VUE_APP_STAGE) {
  runtimeconfig.stage = process.env.VUE_APP_STAGE;
}

export default runtimeconfig;
