import Vue from "vue";
import EncryptedInputComponent from "@/components/encrypted-input.vue";
import TooltipComponent from "@/components/tooltip.vue";
import { lookupService } from "./services/lookup.service";
import CardWithSwitchComponent from "@/components/card-with-switch.vue";
import "./filters/time-converters";

Vue.component("encrypted-input", EncryptedInputComponent);
Vue.component("tooltip", TooltipComponent);
Vue.component("card-with-switch-component", CardWithSwitchComponent);

Vue.prototype.$lookupService = lookupService;
