<template>
  <v-card>
    <v-card-title>
      <tooltip :message="hint">
        <v-switch
          :data-cy="dataCy"
          :label="title"
          v-model="model"
          @change="onChange"
          :disabled="disabled"
        ></v-switch>
      </tooltip>
    </v-card-title>
    <v-card-text v-if="isOpen">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card__title {
  padding: 0 16px 0px 16px;
}
</style>

<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Tooltip from "./tooltip.vue";
@Component({
  components: {
    Tooltip,
  },
})
export default class CardWithSwitchComponent extends Vue {
  @Prop() isOpen: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop() title: string;
  @Prop() dataCy: string;
  @Prop() hint: string;

  model = false;

  onChange(isEnabled: boolean) {
    this.$emit("change", isEnabled);
  }

  mounted() {
    this.model = this.isOpen;
  }

  @Watch("isOpen")
  onIsOpenChanged() {
    this.model = this.isOpen;
  }
}
</script>
