/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from "axios";
import runtimeconfig from "@/runtime_config";
import { NSwagBaseClass } from "./nswag-base-class";

export class NSwagBaseClassDataHub extends NSwagBaseClass {
  replaceApiGatewayUrl(url: string): string {
    return url.replaceAll("/api/v1/datahub", ""); // This segment is set in the API Gateway config
  }

  getAxiosInstance(): AxiosInstance {
    return axios.create({
      baseURL: runtimeconfig.datahub,
    });
  }
}
