import { ApiResult } from "@/nswag";
import { TenantDto, UserService } from "./datahub-api.generated";

export class CachedTenantService {
  private cache: TenantDto[] | null = null;
  private userService = new UserService();

  async getMyTenants(): Promise<ApiResult<TenantDto[]>> {
    if (this.cache !== null) {
      return ApiResult.Success(this.cache);
    }

    const tenants = await this.userService.tenants();

    if (tenants.isFailure) {
      return tenants;
    }

    this.cache = tenants.result;

    return ApiResult.Success(this.cache);
  }
}
