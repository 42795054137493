<template>
  <v-progress-linear
    :active="loading"
    :indeterminate="loading"
    height="10"
    absolute
  ></v-progress-linear>
</template>
<script>
import eventHub from "@/eventhub";
export default {
  name: "progress-bar",
  data() {
    return { loading: false };
  },
  methods: {
    showProgressBar() {
      this.loading = true;
    },
    hideProgressBar() {
      this.loading = false;
    }
  },
  created() {
    eventHub.$on("before-request", this.showProgressBar);
    eventHub.$on("after-response", this.hideProgressBar);
  },
  beforeDestroy() {
    eventHub.$off("before-request", this.showProgressBar);
    eventHub.$off("after-response", this.hideProgressBar);
  }
};
</script>