import LZString from 'lz-string'

export default class SessionStorage {
  setItem (key, value) {
    const value_ = LZString.compressToEncodedURIComponent(value)
    const key_ = LZString.compressToEncodedURIComponent(key)
    sessionStorage.setItem(key_, value_)
  }

  getItem (key) {
    const key_ = LZString.compressToEncodedURIComponent(key)
    let result = sessionStorage.getItem(key_)
    if (result) {
      result = LZString.decompressFromEncodedURIComponent(result)
    }
    return result
  }

  removeItem (key) {
    const key_ = LZString.compressToEncodedURIComponent(key)
    sessionStorage.removeItem(key_)
  }

  key (index) {
    return LZString.decompressFromEncodedURIComponent(sessionStorage.key(index))
  }
}
