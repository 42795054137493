<template>
  <v-card class="mx-auto" outlined>
    <v-app-bar>
      <h4>Links</h4>
      <v-spacer></v-spacer>
      <tooltip message="Add link">
        <v-btn @click="add()" class="mx-2" small fab>
          <v-icon data-cy="add">mdi-plus</v-icon>
        </v-btn>
      </tooltip>
    </v-app-bar>
    <v-simple-table>
      <thead>
        <tr>
          <td>Label</td>
          <td>Url</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in links" :key="i">
          <td>
            <v-text-field
              v-model="item.label"
              :rules="rules.required"
              data-cy="link-label"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="item.uri"
              :rules="rules.url"
              data-cy="link-uri"
            ></v-text-field>
          </td>
          <td>
            <tooltip message="Delete">
              <friss-icon
                data-cy="paramdelete"
                @click="remove(i)"
                name="delete"
              ></friss-icon>
            </tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";

export default {
  name: "headers",
  components: {
    tooltip,
  },
  props: {
    links: {
      type: Array,
    },
  },
  data: function () {
    return {
      rules: {
        url: Validators.Required.Url,
        required: Validators.Required.Text,
      },
    };
  },
  methods: {
    add() {
      this.links.push({ label: "", uri: "" });
    },
    remove(index) {
      this.links.splice(index, 1);
    },
  },
};
</script>
