import Vue from "vue";
import { CanModifyDataDto } from "./datahub-api";

// from https://stackoverflow.com/questions/50111231/vue-prototype-not-reactive-when-data-changes
class Permissions extends Vue {
  private VM: Vue;

  constructor() {
    super();
    this.VM = new Vue({
      data: () => ({
        permissions: {
          canModifyDataSource: false,
          canModifyConnection: false,
          canModifyCertificate: false,
        },
      }),
    });
  }

  update(permissions: CanModifyDataDto) {
    this.VM.$data.permissions = permissions;
    console.debug("Permissions updated", permissions);
  }

  get state() {
    return this.VM.$data;
  }

  get canModifyDataSource() {
    return this.VM.$data.permissions.canModifyDataSource;
  }

  get canModifyConnection() {
    return this.VM.$data.permissions.canModifyConnection;
  }

  get canModifyCertificate() {
    return this.VM.$data.permissions.canModifyCertificate;
  }
}

export default {
  Permissions: Permissions,
  install(Vue: any, options: any) {
    Vue.mixin({
      beforeCreate() {
        this.$permissions = options.store;
      },
    });
  },
};
