<template>
  <v-dialog v-model="showDialog" persistent :max-width="550">
    <v-card class="mx-auto" outlined>
      <v-app-bar>
        <h2>Data provider</h2>
      </v-app-bar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-text-field
                data-cy="name"
                v-model="model.name"
                :disabled="!!dataProviderId"
                label="Name"
                :rules="rules.name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                data-cy="description"
                v-model="model.description"
                label="Description"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <dataProviderLinks :links="model.links" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn data-cy="cancel" color="secondary" outlined @click="cancel">Cancel</v-btn>
        <v-btn
          data-cy="save"
          v-if="$permissions.canModifyDataSource"
          @click="checkAndSave"
          color="primary"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API } from "@/datahub-api";
import { Validators } from "@/helpers";
import constants from "@/services/constants";
import dataProviderLinks from "@/views/dataprovider/dataprovider-links.vue";

export default {
  name: "datasource",
  props: ["dataProviderId"],
  components: {
    dataProviderLinks,
  },
  data: () => ({
    showDialog: true,
    model: {
      links: [],
    },
    rules: {
      name: Validators.Required.Text,
    },
  }),
  async mounted() {
    await this.get();
    this.$refs?.form?.resetValidation();
  },
  methods: {
    get: async function () {
      if (this.dataProviderId) {
        var response = await API.dataProviderService.get(this.dataProviderId);
        if (response.isSuccess) {
          this.model = response.result;
        }
      }
    },
    async checkAndSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.model.links.length === 0) {
        this.$root.$alert(
          "The link to documentation is not filled in. Please confirm that you want to proceed without adding it.",
          {
            width: 320,
            header: "Do you want to proceed?",
            yesNo: true,
            callback: async (result) => {
              if (result.accepted) {
                await this.save();
              }
            },
          }
        );
        return;
      }
      await this.save();
    },
    async save() {
      let response = !this.model.id
        ? await API.dataProviderService.create(this.model)
        : await API.dataProviderService.update(this.model);

      if (response.isSuccess) {
        this.showDialog = false;
        this.model = {};
        this.$emit("saved");
        this.$emit("close");
      }
    },
    cancel() {
      this.showDialog = false;
      this.$emit("close");
    },
  },
};
</script>
