<template>
  <v-card>
    <v-card-text>
      <h4>From</h4>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <td width="10%">Aggregator</td>
              <td width="20%">Path</td>
              <td width="70%">Where</td>
              <td>
                <tooltip message="Add" v-if="canAddFrom()">
                  <v-icon @click="add()" data-cy="fromadd">mdi-plus</v-icon>
                </tooltip>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in froms" :key="i">
              <td>
                <v-select
                  :items="$lookupService.selectMethods"
                  v-model="item.selectMethod"
                ></v-select>
              </td>
              <td>
                <v-text-field
                  v-if="!schemaModel || schemaModel.schema.length === 0"
                  v-model="item.path"
                  data-cy="frompath"
                  :rules="rules.fromPath"
                ></v-text-field>

                <v-combobox
                  v-else
                  v-model="item.path"
                  :items="getSchemaFromMapped(item)"
                  data-cy="frompath"
                  :rules="rules.fromPath"
                  @change="(e) => selectionChange(e, i)"
                  clearable
                ></v-combobox>
              </td>
              <td>
                <whereComp :wheres="item.wheres" :schema="getSchemaWhere(item)" />
              </td>
              <td>
                <tooltip message="Delete">
                  <friss-icon
                    data-cy="paramdelete"
                    v-if="$permissions.canModifyDataSource"
                    @click="remove(i)"
                    name="delete"
                  ></friss-icon>
                </tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import whereComp from "@/views/expression/expression.where.vue";
import tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";
import constants from "@/services/constants";
import { flatten } from "@/lib/functions";
import { flattenToNodesWithLeaves } from "@/lib/functions";

export default {
  name: "from",
  components: {
    whereComp,
    tooltip,
  },
  props: {
    froms: {},
    schemaModel: {},
  },
  data: function () {
    return {
      rules: {
        fromPath: Validators.Required.Text,
      },
    };
  },
  methods: {
    add() {
      let item = {
        path: "",
        wheres: [],
        selectMethod: constants.selectMethods.all,
      };
      this.froms.push(item);
      if (this.schemaModel) {
        if (this.froms.length === 1) {
          this.schemaModel.fromSchemaMap.set(item, this.schemaModel.schema);
        } else {
          var parent = this.froms[this.froms.length - 2];
          var schemaFrom = this.buildSchemaFromFor(parent);
          this.schemaModel.fromSchemaMap.set(item, schemaFrom);
        }
      }
    },
    remove(index) {
      var from = this.froms[index];
      this.schemaModel?.fromSchemaMap?.delete(from);

      this.froms.splice(index, 1);
    },
    buildSchemaFromFor(parent) {
      var schema = this.getSchemaFrom(parent);
      var selected = schema?.find((x) => x.path === parent.path);
      if (!selected) {
        return null;
      }
      var root = {
        elementName: "",
        schema: selected.element.schema,
      };
      return flattenToNodesWithLeaves(root);
    },
    selectionChange(path, index) {
      if (this.froms.length > index + 1) {
        this.froms
          .splice(index + 1, this.froms.length - index)
          .forEach((f) => this.schemaModel.fromSchemaMap.delete(f));
      }
      var from = this.froms[index];
      var selectedSchema = this.getSchemaWhere(from);
      this.$emit("selected", selectedSchema);
    },
    getSchemaFrom(from) {
      return this.schemaModel?.fromSchemaMap?.get(from);
    },
    getSchemaFromMapped(from) {
      return this.schemaModel?.fromSchemaMap?.get(from)?.map((x) => x.path);
    },
    getSchemaWhere(from) {
      var schema = this.getSchemaFrom(from);
      var selected = schema?.find((x) => x.path === from.path);
      if (!selected) {
        return null;
      }
      return flatten(selected.element.schema, "/");
    },
    canAddFrom() {
      var last = this.froms?.at(-1);
      if (!last) return true;
      var schema = this.buildSchemaFromFor(last);
      if (!schema) return true;
      return schema.length > 0;
    },
  },
};
</script>
