<template>
  <v-dialog v-model="showDialog" persistent :max-width="1600">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-card class="mx-auto" outlined>
      <v-app-bar>
        <h2>Expression</h2>
      </v-app-bar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col md="3">
              <v-text-field
                data-cy="name"
                v-model="model.name"
                label="Expression Name"
                :rules="rules.name"
              ></v-text-field>
            </v-col>
            <v-col md="3">
              <v-text-field
                data-cy="orderBy"
                v-model="model.query.orderBy.elementName"
                label="Order BY"
              ></v-text-field>
            </v-col>
            <v-col md="3">
              <v-checkbox
                data-cy="descending"
                v-model="model.query.orderBy.descending"
                label="Descending"
              ></v-checkbox>
            </v-col>
            <v-col md="12">
              <fromComp
                :froms="model.query.froms"
                :schemaModel="schemaModel"
                @selected="parentSchemaChanged"
              />
            </v-col>
            <v-col md="12">
              <aggregationComp
                :aggregations="model.query.aggregations"
                :schema="selectedSchema"
              />
            </v-col>
            <v-col md="12">
              <selectComp :select="model.query.select" :schema="selectedSchema" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn data-cy="cancel" color="secondary" rounded outlined @click="cancel"
          >Cancel</v-btn
        >
        <v-btn
          data-cy="save"
          color="primary"
          rounded
          v-if="$permissions.canModifyDataSource && isDraft"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.col {
  padding: 5px;
}
</style>

<script>
import aggregationComp from "@/views/expression/expression.aggregation.vue";
import selectComp from "@/views/expression/expression.select.vue";
import fromComp from "@/views/expression/expression.from.vue";
import { Validators } from "@/helpers";
import eventHub from "@/eventhub";
import constants from "@/services/constants";
import { flatten } from "@/lib/functions.ts";
import { flattenToNodesWithLeaves } from "@/lib/functions";
import { API } from "@/datahub-api";
import { ApiResult } from "@/nswag";

export default {
  name: "expression",
  props: {
    expressionId: String,
    isDraft: Boolean,
    schema: null,
    dataSourceId: String,
  },
  components: {
    aggregationComp,
    selectComp,
    fromComp,
  },
  data: () => ({
    showDialog: true,
    schemaModel: null,
    selectedSchema: null,
    model: {
      query: {
        froms: [],
        select: {
          fields: [],
        },
        aggregations: [],
        wheres: [],
        orderBy: {},
      },
    },
    rules: {
      name: Validators.Required.Text,
    },
  }),
  async created() {
    this.model = {
      query: {
        froms: [],
        select: {
          fields: [],
        },
        aggregations: [],
        wheres: [],
        orderBy: {},
      },
    };
    if (this.schema) {
      this.schemaModel = this.buildDataServiceSchemaModel(this.schema);
    }
    await this.getExpression();
  },
  methods: {
    async getExpression() {
      if (!this.expressionId) {
        return;
      }

      var response = await API.expressionService.get(this.expressionId);
      if (response.isSuccess) {
        this.model = response.result;
        this.model.isInitial = this.isInitial;
        if (this.schemaModel) {
          this.buildDependentModels(this.model.query.froms);
        }
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        eventHub.$emit("notification", constants.clientValidation.defaultMessage);
        return;
      }

      let response = null;

      if (
        !this.model.id ||
        this.model.id.value === constants.guid.empty ||
        !this.model.dataSourceCorrelationId ||
        this.model.dataSourceCorrelationId === constants.guid.empty
      ) {
        // i'm creating a new expression
        const dataSource = await API.dataSourceService.get(this.dataSourceId);
        this.model.dataSourceCorrelationId = dataSource.result.correlationId;
        response = await API.expressionService.create(this.model);
      } else {
        response = await API.expressionService.update(this.model);
      }

      if (response.isSuccess) {
        this.showDialog = false;
        this.$emit("saved");
        this.$emit("close");
      }
    },
    cancel() {
      this.showDialog = false;
      this.$emit("close");
    },
    buildDataServiceSchemaModel(schema) {
      var root = {
        elementName: "root",
        schema: [
          {
            elementName: "scores",
          },
          {
            elementName: "source",
            schema: schema,
          },
        ],
      };
      return {
        schema: flattenToNodesWithLeaves(root),
        fromSchemaMap: new Map(), // will be used for dependant from components to store selection state
      };
    },
    buildDependentModels(froms) {
      froms?.forEach((from, index) => {
        var node = this.schemaModel.schema.find((x) => x.path === from.path);
        if (node) {
          // first from is found
          this.schemaModel.fromSchemaMap.set(from, this.schemaModel.schema);
          return;
        }
        if (index > 0) {
          // for next froms load previous in the list which is a parent
          var parent = this.model.query.froms.at(index - 1);
          if (parent) {
            // get schema for parent
            var schema = this.schemaModel.fromSchemaMap.get(parent);
            // find selected node in parent schema
            var selected = schema.find((x) => x.path === parent.path);
            var root = {
              elementName: "",
              schema: selected.element.schema,
            };
            // build schema for selected node (from)
            var schemaFrom = flattenToNodesWithLeaves(root);
            // store it in the dictionary
            this.schemaModel.fromSchemaMap.set(from, schemaFrom);
          }
        }
      });
      var last = this.model.query.froms.at(-1);
      if (last) {
        // take last from to build model for aggregation and selection
        var schema = this.schemaModel.fromSchemaMap.get(last);
        var selected = schema?.find((x) => x.path === last.path);
        if (!selected) {
          return;
        }
        this.selectedSchema = flatten(selected.element.schema, "/");
      }
    },
    parentSchemaChanged(schema) {
      this.selectedSchema = schema;
    },
  },
};
</script>
