<template>
  <v-card>
    <v-card-text>
      <h4>Visualization</h4>
      <v-text-field
        style="width: 20%"
        v-model="select.label"
        data-cy="tablename"
        label="Table Name"
        :rules="rules.tableName"
      ></v-text-field>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <td width="60%">Path</td>
              <td width="40%">Label</td>
              <td>
                <tooltip message="Add header">
                  <v-icon @click="add()" data-cy="outputadd">mdi-plus</v-icon>
                </tooltip>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in select.fields" :key="i">
              <td>
                <v-text-field
                  v-if="!schema || schema.length === 0"
                  v-model="item.path"
                  data-cy="outputfield"
                  :rules="rules.path"
                ></v-text-field>

                <v-combobox
                  v-else
                  v-model="item.path"
                  :items="schema.map((x) => x.path)"
                  data-cy="outputfield"
                  item-text="path"
                  item-value="path"
                  :rules="rules.path"
                  clearable
                ></v-combobox>
              </td>
              <td>
                <v-text-field
                  v-model="item.label"
                  data-cy="outputlabel"
                  :rules="rules.label"
                ></v-text-field>
              </td>
              <td>
                <tooltip message="Delete">
                  <friss-icon
                    data-cy="paramdelete"
                    v-if="$permissions.canModifyDataSource"
                    @click="remove(i)"
                    name="delete"
                  ></friss-icon>
                </tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.selectLabel {
  width: 20%;
}
</style>

<script>
import tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";

export default {
  name: "fields",
  props: {
    select: {
      fields: [],
    },
    schema: null,
  },
  components: {
    tooltip,
  },
  data: function () {
    return {
      rules: {
        path: Validators.Required.Text,
        label: Validators.Required.Text,
      },
    };
  },
  methods: {
    add() {
      let item = { path: "", label: "" };
      this.select.fields.push(item);
    },
    remove(index) {
      this.select.fields.splice(index, 1);
    },
  },
};
</script>
