<template>
  <card-with-switch-component
    :isOpen="model.asyncOptions"
    title="Is asynchronous"
    hint="Enable asynchronous behavior"
    @change="onAsyncEnabledChange"
    :disabled="model.responseType === ResponseType.Raw"
    data-cy="asyncEnabled"
  >
    <v-row v-if="model.asyncOptions">
      <v-col cols="6">
        <v-text-field
          id="panelAsyncTimeout"
          data-cy="timeout"
          :value="model.asyncOptions.timeout | secondsTo('minutes')"
          label="Timeout (minutes)"
          type="number"
          :rules="rules.timeout"
          ref="timeout"
          hint="The amount of time after which the background polling will be stopped"
          persistent-hint
          @input="
            (value) => {
              model.asyncOptions.timeout = convert.minutesTo(value, 'seconds');
              validateRetryTimespans();
            }
          "
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          id="panelAsyncInterval"
          data-cy="interval"
          :value="model.asyncOptions.interval | secondsTo('minutes')"
          type="number"
          label="Interval (minutes)"
          :rules="rules.interval"
          ref="interval"
          @input="
            (value) => {
              model.asyncOptions.interval = convert.minutesTo(value, 'seconds');
              validateRetryTimespans();
            }
          "
          hint="The time to wait between each background poll"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>
  </card-with-switch-component>
</template>
<script lang="ts">
import CardWithSwitchComponent from "@/components/card-with-switch.vue";
import { DataSourceConfigurationDto, ResponseType } from "@/datahub-api";
import { minutesTo, secondsTo } from "@/filters/time-converters";
import { Validators } from "@/helpers";
import { VuetifyControl } from "@/plugins";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    CardWithSwitchComponent,
  },
})
export default class DataSourceAsyncComponent extends Vue {
  @Prop() model: DataSourceConfigurationDto;

  ResponseType = ResponseType;

  convert = {
    secondsTo,
    minutesTo,
  };

  private timeSpansInSeconds = {
    minutes: (min: number) => this.convert.minutesTo(min, "seconds"),
  };

  private timeSpansInMinutes = {
    oneDay: 1440,
    oneWeek: 10080,
  };

  get rules() {
    return {
      timeout: [
        Validators.Numbers.Required,
        Validators.Numbers.Min(3),
        Validators.Numbers.Max(this.timeSpansInMinutes.oneWeek),
        this.crossValidateTimeout,
      ],
      interval: [
        Validators.Numbers.Required,
        Validators.Numbers.Min(1),
        Validators.Numbers.Max(this.timeSpansInMinutes.oneDay),
        this.crossValidateInterval,
      ],
    };
  }

  $refs: {
    timeout: VuetifyControl;
    interval: VuetifyControl;
  };

  crossValidateTimeout(value: string | number | null) {
    value = this.convert.minutesTo(value, "seconds");

    if (value && !this.model.asyncOptions?.interval) {
      return "You need to specify also the interval";
    }

    if (Number(value) < Number(this.model.asyncOptions?.interval)) {
      return "Timeout can not be less then interval";
    }

    return true;
  }
  crossValidateInterval(value: string | number | null) {
    value = this.convert.minutesTo(value, "seconds");

    if (value && !this.model.asyncOptions?.timeout) {
      return "You need to specify also the timeout";
    }

    if (Number(value) > Number(this.model.asyncOptions?.timeout)) {
      return "Interval can not be greater then timeout";
    }

    return true;
  }

  onAsyncEnabledChange(isEnabled: boolean) {
    if (isEnabled) {
      this.model.asyncOptions = {
        interval: this.timeSpansInSeconds.minutes(1)!,
        timeout: this.timeSpansInSeconds.minutes(60)!,
      };
      this.model.retry = null;
    } else {
      this.model.asyncOptions = null;
    }
  }

  async validateRetryTimespans() {
    await this.$nextTick();
    this.$refs.timeout.validate();
    this.$refs.interval.validate();
  }
}
</script>
