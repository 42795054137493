<template>
  <v-card>
    <v-card-text>
      <h4>Custom authentication</h4>
      <v-row>
        <v-col md="6">
          <encrypted-input
            v-model="model.username"
            label="User name"
            data-cy="username"
            :readonly="readonly"
          ></encrypted-input>
        </v-col>
        <v-col md="6">
          <encrypted-input
            v-model="model.password"
            label="Password"
            data-cy="password"
            :readonly="readonly"
          ></encrypted-input
        ></v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="model.headerName"
            label="Authorization Header Name"
            data-cy="header"
            :readonly="readonly"
          ></v-text-field>
        </v-col>

        <v-col cols="4" data-cy="httpverb">
          <v-select
            :items="$lookupService.httpVerbs"
            v-model="model.tokenHttpMethod"
            label="Authentication Http Verb"
          ></v-select>
        </v-col>
        <v-col md="4">
          <v-text-field
            v-if="showExpiresIn"
            v-model="model.expiresIn"
            label="Token cache expires in (seconds)"
            data-cy="expires"
            :rules="rules.expiresIn"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <headers
            v-if="model.tokenHeaders"
            :customHeaders="model.tokenHeaders"
            v-on:updateHeaders="updateHeaders"
            :readonly="readonly"
            title="Authentication headers"
            data-cy="auth-headers"
          />
        </v-col>
        <v-col md="6">
          <v-card>
            <v-card-text>
              <h4>Authentication body</h4>
              <additional-fields-component
                :model="model.additionalFields"
                @onChange="onAuthenticationBodyChange"
                :readonly="readonly"
              >
              </additional-fields-component>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import headers from "@/views/connection/headers.vue";
import { Validators } from "@/helpers";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  CustomTokenCredentialsDto,
  HttpVerb,
  KeyValuePairOfStringAndString,
} from "@/datahub-api";
import AdditionalFieldsComponent from "@/components/additional-fields.vue";

@Component({
  components: {
    headers,
    AdditionalFieldsComponent,
  },
})
export default class CustomTokenAuthComponent extends Vue {
  @Prop() model: CustomTokenCredentialsDto;
  @Prop({ default: false }) readonly: boolean;

  @Watch("model.tokenHttpMethod")
  onTokenHttpMethodChanged() {
    this.setExpiryTime();
  }

  created() {
    this.setExpiryTime();
  }

  updateHeaders(headers: KeyValuePairOfStringAndString[] | null) {
    this.model.tokenHeaders = JSON.parse(JSON.stringify(headers));
  }

  get showExpiresIn() {
    return this.model.tokenHttpMethod === HttpVerb.Get;
  }

  setExpiryTime() {
    if (this.showExpiresIn) {
      this.model.expiresIn ??= 3600;
    } else {
      this.model.expiresIn = null;
    }
  }

  rules = {
    expiresIn: [
      Validators.Numbers.Required,
      Validators.Numbers.Min(0),
      Validators.Numbers.Max(3600),
    ],
  };

  onAuthenticationBodyChange(dict: { [key: string]: string } | null) {
    this.model.additionalFields = JSON.parse(JSON.stringify(dict));
  }
}
</script>
