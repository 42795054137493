import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@mdi/font/css/materialdesignicons.css";

import FrissLogoIcon from "@/components/icons/FrissLogoIcon.vue";
import FrissIcon from "@/components/icons/FrissIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: {
          base: "#FF8000",
        },
        secondary: {
          base: "#000B4D",
        },
        accent: {
          base: "#000B4D",
        },
        error: {
          base: "#6D1099",
        },
        warning: {
          base: "#FFCC00",
        },
        success: {
          base: "#7DBF3A",
        },
        info: {
          base: "#6D1099",
        },
      },
    },
  },
  icons: {
    values: {
      frissLogo: {
        component: FrissLogoIcon,
      },
      frissIcon: {
        component: FrissIcon,
      },
    },
  },
});

export type VuetifyForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
  inputs: VuetifyControl[];
};
export type VuetifyControl = Vue & {
  validate: () => boolean;
  hasError: boolean;
  $refs: { input: VuetifyControl & { id?: string } };
};
