<template>
  <v-row v-if="connection.credential">
    <v-col cols="4">
      <v-select
        :items="certificates"
        v-model="connection.credential.certificateId"
        item-text="name"
        item-value="id"
        label="Certificate"
        data-cy="certificate"
        :rules="rules.certificateId"
        :readonly="readonly"
      >
        <template slot="append-outer" v-if="$permissions.canModifyCertificate">
          <tooltip message="Upload new certificate">
            <v-btn
              x-small
              fab
              data-cy="certificate-add"
              @click="showAddCertificateDialog = true"
              :disabled="readonly"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </tooltip>
          <createCertificateDialog
            v-if="showAddCertificateDialog"
            :dataProviderId="dataProviderId"
            @saved="refreshCertificates"
            @close="showAddCertificateDialog = false"
          >
          </createCertificateDialog>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { Validators } from "@/helpers";
import moment from "moment";
import tooltip from "@/components/tooltip.vue";
import createCertificateDialog from "@/views/certificates/create-edit-certificate.vue";
import { API, ConnectionDto, CredentialBaseDto, CertificateDto } from "@/datahub-api";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    tooltip,
    createCertificateDialog,
  },
})
export default class CertificateComponent extends Vue {
  @Prop() dataProviderId: string;
  @Prop() readonly: boolean;
  @Prop() connection: ConnectionDto & { credential: CredentialBaseDto };

  showAddCertificateDialog = false;
  rules = {
    certificateId: Validators.Required.Guid,
  };
  certificates: Array<CertificateDto> = [];

  async mounted() {
    await this.refreshCertificates();
  }
  async refreshCertificates() {
    let response = await API.dataProviderService.getDataProviderCertificates(
      this.dataProviderId
    );

    if (response.isFailure) {
      return;
    }

    const certificates = (response.result || []).map(
      (cert) =>
        ({
          ...cert,
          expirationDate: this.formatDate(cert.expirationDate),
        } as CertificateDto)
    );

    this.certificates = certificates;
  }

  formatDate(dateTime: string) {
    if (!dateTime) {
      return null;
    }

    return moment(dateTime).local().format("DD MMM YYYY");
  }
}
</script>
