<template>
  <v-snackbar v-model="snackbar" :class="'type-' + type">
    <div style="display: flex; justify-content: space-between; align-items: center">
      <friss-icon :name="icon"></friss-icon>
      <div style="margin: 0px 16px; line-height: 20pt">
        {{ text }}
      </div>
      <v-btn fab text x-small @click="snackbar = false"
        ><friss-icon name="cross"></friss-icon
      ></v-btn>
    </div>
  </v-snackbar>
</template>

<style>
.v-snack.type-warning .v-snack__wrapper {
  /* background-color: var(--notify-regular); */
}
.v-snack.type-success .v-snack__wrapper {
  background-color: var(--green-regular);
}
</style>

<script>
import eventHub from "@/eventhub";
export default {
  name: "snack-bar",
  data: () => ({
    snackbar: false,
    text: "",
    type: "warning",
  }),
  methods: {
    showSnackBar(message, type) {
      this.snackbar = true;
      this.text = message;
      this.type = type || "warning";
    },
  },
  created() {
    eventHub.$on("notification", this.showSnackBar);
    eventHub.$on("success", (message) => this.showSnackBar(message, "success"));
  },
  beforeDestroy() {
    eventHub.$off("notification", this.showSnackBar);
    eventHub.$off("success", (message) => this.showSnackBar(message, "success"));
  },
  computed: {
    icon() {
      switch (this.type) {
        case "success":
          return "success-alt";
        default:
          return "warning";
      }
    },
  },
};
</script>
