<template>
    <v-row>
        <v-col cols="12">
            <v-text-field
                data-cy="uri"
                v-model="dataSourceStage.dataSourceUri"
                :label="dataSourceStage.name + ' base url'"
                :hint="
          'Example: https://' +
          dataSourceStage.name.toLowerCase() +
          '.vendor.com (do not put the query params here!)'
        "
                persistent-hint
                :rules="rules.url"
                :readonly="isFrissDataProvider"
            ></v-text-field>
        </v-col>
    </v-row>
</template>
<script>
import {Validators} from "@/helpers";

export default {
    props: {
        dataSourceStage: {
            proxy: {},
        },
        isFrissDataProvider: Boolean,
    },
    components: {},
    data() {
        return {
            rules: {
                url: Validators.Required.Url,
            },
        };
    },
};
</script>
