<template>
  <v-card class="mx-auto" outlined style="margin=top: 12px">
    <v-app-bar
      ><img src="/img/friss-logo.svg" width="70px" style="margin-right: 12px" />
      Data Service
    </v-app-bar>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-if="model.configuration.dataServiceSettings"
            v-model="model.configuration.dataServiceSettings.name"
            :items="dataSources"
            label="Data Source"
            data-cy="datasource"
            item-text="name"
            item-value="name"
            @change="dataServiceSelectionChange"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-if="itemsFromSchema && itemsFromSchema.length > 0"
            dense
            data-cy="fields-table"
            :headers="headers"
            :items="itemsFromSchema"
            :items-per-page="5"
            :search="search"
            item-key="path"
            v-model="model.configuration.dataServiceSettings.searchFields"
            show-select
          >
            <template v-slot:[`item.data-table-select`]="{ isSelected, select, item }">
              <v-simple-checkbox
                :data-cy="'select-' + item.path"
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                data-cy="search-fields"
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
            </template>
            <template v-slot:[`item.path`]="{ item }">
              <v-text-field
                v-model="item.path"
                readonly
                :data-cy="'path-' + item.path"
              ></v-text-field>
            </template>
            <template v-slot:[`item.query`]="{ item }">
              <v-text-field
                v-model="item.query"
                :data-cy="'query-' + item.path"
                :disabled="!selected(item.path)"
                :rules="selected(item.path) ? rules.required : []"
                @change="onItemChanged(item)"
              ></v-text-field>
            </template>
            <template v-slot:[`item.fuzzyMatch`]="{ item }">
              <v-checkbox
                v-model="item.fuzzyMatch"
                :data-cy="'fuzzy-match-' + item.path"
                @change="onItemChanged(item)"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.threshold`]="{ item }">
              <v-text-field
                v-model="item.threshold"
                :disabled="!item.fuzzyMatch"
                :data-cy="'threshold-' + item.path"
                @change="onItemChanged(item)"
                type="number"
                :rules="item.fuzzyMatch ? rules.threshold : []"
              ></v-text-field>
            </template>
            <template v-slot:[`item.matchType`]="{ item }">
              <v-select
                :items="$lookupService.matchingTypes"
                v-model="item.matchType"
                :disabled="!item.fuzzyMatch"
                :data-cy="'match-type-' + item.path"
                @change="onItemChanged(item)"
              ></v-select>
            </template>
          </v-data-table>
          <span v-if="errorMessage" style="color: red">
            {{ errorMessage }}
          </span>
          <div style="display: flex; justify-content: end">
            <confirm
              :disabled="applyDisabled"
              title="Apply data service"
              yesText="Apply"
              noText="Cancel"
              color="secondary"
              message="
              Applying data service request will override current request. Are you sure you want to apply the data service request?
            "
              @confirmed="applyDataServiceRequest()"
            >
              <v-btn
                :disabled="applyDisabled"
                rounded
                color="secondary"
                outlined
                data-cy="apply"
                >Apply</v-btn
              >
            </confirm>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import confirm from "@/components/confirm.dialog.vue";
import { Validators } from "@/helpers";
import constants from "@/services/constants";
import { flatten } from "@/lib/functions";
import {
  DATASERVICE_API,
  MatchingItemType,
  SchemaElementDto,
  SearchFieldDto,
  SearchMode,
  SearchQueryDto,
  SourceConfigurationDto,
  SourceConfigurationListItemDto,
} from "@/dataservice-api";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  DataSourceDto,
  SearchFieldDto as DataHubFieldDto,
  DataSourceConfigurationDto,
} from "@/datahub-api";
import eventHub from "@/eventhub";

@Component({
  components: {
    confirm,
  },
})
export default class DataSourceDataServiceComponent extends Vue {
  @Prop() model: DataSourceDto;

  search = "";
  errorMessage = "";
  dataSources: SourceConfigurationListItemDto[] = [];
  dataSource: SourceConfigurationDto | null = null;
  itemsFromSchema: Array<SearchFieldDto | undefined> | null = null;
  rules = {
    required: Validators.Required.Text,
    threshold: [
      (value: number | null | undefined) => {
        if (value != null) {
          if (value >= 0) {
            return Validators.Numbers.Max(100)(value);
          } else {
            return Validators.Numbers.Min(0)(value);
          }
        }

        return true;
      },
    ],
  };
  headers = [
    { text: "Path", value: "path", width: "50%" },
    { text: "Query", value: "query", width: "30%" },
    { text: "FuzzyMatch", value: "fuzzyMatch", width: "100px" },
    { text: "Threshold", value: "threshold", width: "100px" },
    { text: "Match type", value: "matchType", width: "200px" },
  ];

  get applyDisabled() {
    return (
      !this.model.configuration!.dataServiceSettings?.searchFields ||
      this.model.configuration!.dataServiceSettings.searchFields.length === 0 ||
      this.model.configuration!.dataServiceSettings.searchFields.some(
        (x) => this.rules.required[0](x.query) !== true
      )
    );
  }

  async mounted() {
    if (this.dataSources.length === 0) {
      this.dataSources = await this.getAll();
    }
    if (!this.model.configuration!.dataServiceSettings) {
      this.model.configuration!.dataServiceSettings = {
        name: null,
        searchFields: [],
      };
    } else if (this.model.configuration!.dataServiceSettings.name) {
      await this.loadDataSource(this.model.configuration!.dataServiceSettings.name);
    }
  }

  async dataServiceSelectionChange(dataSourceId: string) {
    if (dataSourceId) {
      this.model.configuration!.dataServiceSettings!.searchFields = [];
      await this.loadDataSource(dataSourceId);
    }
  }
  async loadDataSource(dataSourceId: string) {
    this.errorMessage = "";
    this.dataSource = await this.getById(dataSourceId);
    if (!this.dataSource) {
      this.errorMessage = `Data source '${dataSourceId}' was not found in FRISS DataService`;
      eventHub.$emit(
        "notification",
        `Unable to load source '${dataSourceId}' from FRISS DataService. Ensure it does still exist and that DataService is running`
      );
      return;
    }
    var schema = this.dataSource.schema || [];
    this.itemsFromSchema = this.getItemsFromSchema(schema);
    if (this.itemsFromSchema.length === 0) {
      this.errorMessage = "Data service schema is empty";
    }
  }
  selected(path: string) {
    const isPathSelected =
      this.model.configuration!.dataServiceSettings!.searchFields!.find(
        (x) => x.path === path
      );

    return isPathSelected;
  }
  getItemsFromSchema(schema: SchemaElementDto[]): Array<SearchFieldDto> {
    return flatten(schema).map((flattenedItem) => {
      const item: Partial<SearchFieldDto> = { ...flattenedItem };
      var selected = this.selected(item.path!);
      if (selected) {
        item.query = selected.query!;
        item.fuzzyMatch = selected.fuzzyMatch;
        item.threshold = selected.threshold != null ? Number(selected.threshold) : null;
        item.matchType = selected.matchType as MatchingItemType;
      }

      return item as SearchFieldDto;
    });
  }
  async getAll() {
    var response = await DATASERVICE_API.dataSourceService.getAll([]);
    if (response.isSuccess) {
      response.result.sort((a, b) => a.name.localeCompare(b.name));
      return response.result;
    }
    return [];
  }
  async getById(id: string) {
    var response = await DATASERVICE_API.dataSourceService.getById(id);
    if (!response.isSuccess) {
      return null;
    }
    return response.result;
  }
  applyDataServiceRequest() {
    var requestBody = this.buildDataServiceRequest();
    this.$emit("apply", {
      contentType: constants.contentTypes.json,
      responseType: constants.responseTypes.json,
      httpVerb: constants.httpVerbs.post,
      requestBody: requestBody,
    });
  }
  buildDataServiceRequest() {
    const searchFields = this.model.configuration!.dataServiceSettings!.searchFields!.map(
      (item) => {
        const searchField: Partial<SearchFieldDto> = {
          path: item.path!,
          query: item.query!,
          fuzzyMatch: item.fuzzyMatch || false,
          threshold:
            item.threshold != null && item.threshold.length > 0
              ? Number(item.threshold)
              : null,
          matchType: (item.matchType as MatchingItemType) || null,
        };

        if (!searchField.fuzzyMatch) {
          delete searchField.fuzzyMatch;
        }

        if (searchField.threshold === null || searchField.threshold === undefined) {
          delete searchField.threshold;
        }

        if (!searchField.matchType) {
          delete searchField.matchType;
        }

        return searchField;
      }
    );

    const request: Partial<SearchQueryDto> = {
      source: this.dataSource!.name,
      searchFields: searchFields as SearchFieldDto[],
      searchMode: SearchMode.And,
      size: 10,
    };
    return JSON.stringify(request, null, 4);
  }
  onItemChanged(item: DataHubFieldDto) {
    var selected = this.selected(item.path!);
    if (selected) {
      selected.query = item.query;
      selected.fuzzyMatch = item.fuzzyMatch;
      if (!item.fuzzyMatch) {
        item.threshold = null;
        item.matchType = null;
      }
      selected.threshold = item.threshold;
      selected.matchType = item.matchType;
    }
  }
}
</script>
