/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  Method,
} from "axios";
import mainAuth from "@/auth";
import eventHub from "@/eventhub";

export abstract class NSwagBaseClass {
  abstract replaceApiGatewayUrl(url: string): string;
  abstract getAxiosInstance(): AxiosInstance;

  protected async process(
    method: string,
    url: string,
    options: RequestInit
  ): Promise<ApiResult<any>> {
    if (!mainAuth.accessToken) {
      mainAuth.signOut();
    }

    try {
      eventHub.$emit("before-request");
      const axiosSettings: AxiosRequestConfig = {
        method: method as Method,
        url: this.replaceApiGatewayUrl(url), // This segment is set in the API Gateway config
        data: options.body,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${mainAuth.accessToken}`,
        } as AxiosRequestHeaders,
      };

      const response = await this.getAxiosInstance()(axiosSettings);
      eventHub.$emit("after-response");
      return ApiResult.Success(response.data, response.status);
    } catch (apiError: unknown) {
      eventHub.$emit("after-response");
      eventHub.$emit("notification", extractErrorMessages(apiError));
      return ApiResult.Failure(extractStatusCode(apiError));
    }
  }
}

function extractErrorMessages(apiError: unknown): string {
  try {
    const problemDetails: ProblemDetails = axios.isAxiosError(apiError)
      ? apiError.response?.data
      : apiError;

    if (!problemDetails) {
      return "Internal server error";
    }

    if (problemDetails.detail) {
      // if there is a detail, just return it (exceptions from domain errors)
      return problemDetails.detail;
    }

    if (problemDetails.errors) {
      return Object.entries(problemDetails.errors)
        .flatMap(([_, error]) => {
          if (isDomainError(error)) {
            return [error.message];
          } else {
            return Object.entries(error).flatMap(([, message]) =>
              Array.isArray(message) ? message : [message]
            );
          }
        })
        .join(". ");
    }

    return "Internal server error";
  } catch {
    return "Internal server error";
  }
}

type DomainError = { code: string; message: string };
type ValidationErrors = { [propertyName: string]: string | string[] };

function isDomainError(error: DomainError | ValidationErrors): error is DomainError {
  const e = error as DomainError;
  return e.code != null && e.message != null;
}

function extractStatusCode(apiError: unknown): number {
  const status = axios.isAxiosError(apiError) ? apiError.response?.status : 500;
  return status || 500;
}

interface ProblemDetails {
  title?: string | null;
  detail?: string | null;
  errors?: Array<{ [key: string]: string | Array<string> }> | null;
}

type ApiError = { response?: { data?: ProblemDetails } };

export class ApiResult<T> {
  private _result: T;

  get result() {
    if (this.isFailure) {
      throw new Error("You are trying to access a result for an operation that failed");
    }
    return this._result;
  }

  isSuccess: boolean;

  get isFailure() {
    return !this.isSuccess;
  }

  public status?: number;

  constructor(result: T | null, isSuccess: boolean, status?: number) {
    this._result = result as any;
    this.isSuccess = isSuccess;
    this.status = status;
  }

  public static Success<T>(result: T | null, status?: number) {
    return new ApiResult<T>(result, true, status);
  }

  public static Failure<T>(status?: number) {
    return new ApiResult<T>(null, false, status);
  }
}
