<template>
  <v-icon data-cy="copy" small class="mr-3" @click="copyToClipboard()"
    >mdi-content-copy</v-icon
  >
</template>

<script>
import eventHub from "@/eventhub";
export default {
  name: "copy",
  props: ["content"],
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.content);
      eventHub.$emit("notification", `${this.content} copied to clipboard!`);
    },
  },
};
</script>
