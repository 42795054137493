<template>
  <v-card style="margin: 5px 0">
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <td width="30%">Path</td>
              <td width="20%">DateFormat</td>
              <td width="15%">Type</td>
              <td width="10%">Operator</td>
              <td width="20%">Value</td>
              <td width="5%">Condition</td>
              <td>
                <v-icon @click="add()" data-cy="whereadd">mdi-plus</v-icon>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in wheres" :key="i">
              <td>
                <v-text-field
                  v-if="!schema || schema.length === 0"
                  v-model="item.path"
                  data-cy="wherepath"
                  :rules="rules.path"
                ></v-text-field>
                
                <v-combobox
                  v-else
                  v-model="item.path"
                  :items="schema.map((x) => x.path)"
                  data-cy="wherepath"
                  item-text="path"
                  item-value="path"
                  :rules="rules.path"
                  clearable
                ></v-combobox>
              </td>
              <td>
                  <tooltip message="This is used to parse the datetime values from the vendor response. The most used formats are yyyy-MM-dd, dd-MM-yyyy, MM-dd-yyyy">
                      <v-text-field v-if="isDatetimeField(item.type)"
                                    v-model="item.dateFormat"
                                    data-cy="wheredateformat">
                      </v-text-field>
                  </tooltip>
              </td>
              <td>
                <v-select
                  :items="$lookupService.types"
                  v-model="item.type"
                  data-cy="wheretype"
                ></v-select>
              </td>
              <td>
                <v-select
                  :items="$lookupService.getOperators(item.type)"
                  v-model="item.operator"
                  data-cy="whereoperator"
                ></v-select>
              </td>
              <td>
                <v-text-field v-model="item.value" data-cy="wherevalue"></v-text-field>
              </td>
              <td>
                <v-radio-group row v-if="i > 0" v-model="item.condition">
                  <v-radio :value="0" label="AND" data-cy="and"></v-radio>
                  <v-radio :value="1" label="OR" data-cy="or"></v-radio>
                </v-radio-group>
              </td>
              <td>
                <tooltip message="Delete">
                  <friss-icon
                    data-cy="paramdelete"
                    v-if="$permissions.canModifyDataSource"
                    @click="remove(i)"
                    name="delete"
                  ></friss-icon>
                </tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Validators } from "@/helpers";
import { Vue, Component, Prop } from "vue-property-decorator";
import { CompareOperator, Condition, FieldType, Where } from "@/datahub-api";
import { FlattenedSchemaItem } from "@/lib/functions";

@Component
export default class ExpressionWhereComponent extends Vue {
  @Prop() wheres: Where[];
  @Prop() schema: FlattenedSchemaItem[] | null;

  rules = {
    path: Validators.Required.Text,
  };

  isDatetimeField(type: FieldType) {
    return type === FieldType.DatetimeType;
  }

  add() {
    const item: Where = {
      path: "",
      type: FieldType.StringType,
      operator: CompareOperator.Equal,
      value: "",
      condition: Condition.And,
      dateFormat: ""
    };
    this.wheres.push(item);
  }
  remove(index: number) {
    this.wheres.splice(index, 1);
  }
}
</script>
