<template>
  <v-card class="mx-auto" outlined>
    <v-app-bar>
      <h2>Expressions</h2>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          data-cy="search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-app-bar>
    <v-data-table
      dense
      :headers="headers"
      :items="expressions"
      :items-per-page="15"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :search="search"
      show-expand
      data-cy="expressions-table"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 35px">
          <versionedExpressions
            ref="versionedExpression"
            :expressionCorrelationId="item.correlationId"
            @onDeleted="getAll()"
          ></versionedExpressions>
        </td>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
        <friss-icon
          data-cy="expand_versions"
          tabindex="0"
          title="Show versions"
          @keyup.enter="expand(true)"
          @click="expand(true)"
          v-if="!isExpanded"
          name="chevron-down-alt-lg"
        ></friss-icon>
        <friss-icon
          data-cy="collapse_versions"
          tabindex="0"
          title="Hide versions"
          @keyup.enter="expand(false)"
          @click="expand(false)"
          v-if="isExpanded"
          name="chevron-up-alt-lg"
        ></friss-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <tooltip message="Edit">
          <friss-icon
            data-cy="edit"
            class="mr-3"
            name="edit"
            @click="editItem(item.id)"
          ></friss-icon>
        </tooltip>
        <tooltip message="Copy to...">
          <friss-icon
            data-cy="copy"
            class="mr-3"
            name="copy"
            :disabled="!$permissions.canModifyDataSource"
            @click="() => onCopyExpressionClick(item)"
          ></friss-icon>
        </tooltip>
      </template>
    </v-data-table>
    <div
      v-if="$permissions.canModifyDataSource"
      style="display: flex; padding: 20px; justify-content: end"
    >
      <applyExpressions :datasourceId="datasourceId">
        <v-btn color="secondary" rounded outlined data-cy="apply"> Apply </v-btn>
      </applyExpressions>

      <v-btn
        color="secondary"
        rounded
        outlined
        data-cy="add"
        @click="addItem()"
        class="ml-2"
      >
        Add expression
      </v-btn>
    </div>
    <expression
      v-if="selectedExpressionId !== null"
      :expressionId="selectedExpressionId"
      :dataSourceId="datasourceId"
      :isDraft="true"
      :schema="dataSourceSchema"
      @saved="refreshTables()"
      @close="selectedExpressionId = null"
    >
    </expression>

    <copy-expression
      v-if="expressionToCopy"
      @close="expressionToCopy = null"
      :expression="expressionToCopy"
      :currentDataProviderId="dataproviderId"
      :currentDataSourceId="datasourceId"
      @copied="
        expressionToCopy = null;
        getAll();
      "
    >
    </copy-expression>
  </v-card>
</template>
<script>
import expression from "@/views/expression/expression.vue";
import versionedExpressions from "@/views/expression/expressions.versioned.vue";
import applyExpressions from "@/views/expression/expressions.apply.vue";
import copyExpression from "@/views/expression/expression.copy.vue";
import tooltip from "@/components/tooltip.vue";
import constants from "@/services/constants";
import eventHub from "@/eventhub";
import { API } from "@/datahub-api";
import { DATASERVICE_API } from "@/dataservice-api";

export default {
  components: {
    expression,
    versionedExpressions,
    applyExpressions,
    copyExpression,
    tooltip,
  },
  props: ["dataproviderId", "datasourceId"],
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px",
        },
        { text: "", value: "data-table-expand" },
      ],
      sortBy: "name",
      sortDesc: false,
      expressions: [],
      dataSourceSchema: null,
      selectedExpressionId: null,
      expressionToCopy: null,
    };
  },

  async created() {
    if (this.$route.query.dataProviderName === constants.friss) {
      this.dataSourceSchema = await this.loadDataSourceSchema(this.datasourceId);
    }
    await this.getAll();
  },
  methods: {
    async refreshTables() {
      this.$refs.versionedExpression?.getAll();
      this.getAll();
    },
    async getAll() {
      var response = await API.dataSourceService.getExpressions(this.datasourceId);
      if (response.isSuccess) {
        this.expressions = response.result || [];
      }
    },
    editItem(id) {
      this.selectedExpressionId = id;
    },
    addItem() {
      this.selectedExpressionId = "";
    },
    async loadDataSourceSchema(dataSourceId) {
      if (!dataSourceId) {
        return null;
      }
      var dataSourceResponse = await API.dataSourceService.get(dataSourceId);
      if (dataSourceResponse.isFailure) {
        eventHub.$emit("notification", "Unable to load data source data");
        return null;
      }
      var dataSource = dataSourceResponse.result;
      if (dataSource.configuration.dataServiceSettings?.name) {
        var dataServiceResponse = await DATASERVICE_API.dataSourceService.getById(
          dataSource.configuration.dataServiceSettings.name
        );
        if (dataServiceResponse.isFailure) {
          eventHub.$emit(
            "notification",
            `Unable to load data service configuration '${dataSource.configuration.dataServiceSettings.name}' from DataService. Ensure it does still exist and that DataService is running`
          );
          return null;
        }
        return dataServiceResponse.result.schema;
      }
    },
    onCopyExpressionClick(item) {
      if (this.$permissions.canModifyDataSource) {
        this.expressionToCopy = item;
      }
    },
  },
};
</script>
