<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <td>Key</td>
          <td>Value</td>
          <td>
            <v-icon
              data-cy="addEmpty"
              v-if="$permissions.canModifyDataSource"
              @click="addEmpty()"
              >mdi-plus</v-icon
            >
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in keyValues" :key="i">
          <td>
            <v-text-field
              v-model="item.key"
              label="key"
              data-cy="key"
              :rules="rules.key"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="item.value"
              label="value"
              data-cy="value"
              :rules="rules.value"
            ></v-text-field>
          </td>
          <td>
            <friss-icon
              v-if="$permissions.canModifyDataSource"
              @click="remove(i)"
              name="delete"
            ></friss-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import { Validators } from "@/helpers";

export default {
  props: {
    model: {},
  },
  data() {
    return {
      keyValues: [],
      rules: {
        key: Validators.Required.Text,
        value: Validators.Required.Text,
      },
    };
  },
  mounted() {
    if (!this.model.requestBody) {
      this.addEmpty();
      return;
    }
    this.keyValues = this.getKeyValues();
  },
  methods: {
    getKeyValues() {
      try {
        let keyValues = JSON.parse(this.model.requestBody);
        return Array.isArray(keyValues) ? keyValues : [];
      } catch {
        return [];
      }
    },
    addEmpty() {
      this.keyValues.push({
        key: "",
        value: "",
      });
    },
    remove(index) {
      this.keyValues.splice(index, 1);
    },
  },
  watch: {
    keyValues: {
      handler() {
        this.model.requestBody = JSON.stringify(this.keyValues);
      },
      deep: true,
    },
  },
};
</script>
