import { render, staticRenderFns } from "./snack.bar.vue?vue&type=template&id=5c809184"
import script from "./snack.bar.vue?vue&type=script&lang=js"
export * from "./snack.bar.vue?vue&type=script&lang=js"
import style0 from "./snack.bar.vue?vue&type=style&index=0&id=5c809184&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports