<template>
  <v-app-bar app>
    <v-toolbar-title>
      <router-link to="/" style="text-decoration: none; color: inherit">
        <v-icon>$frissLogo</v-icon>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-spacer></v-spacer>

    <div v-if="isAuthenticated">
      <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" data-cy="options">
            <friss-icon name="person"></friss-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item data-cy="email">{{ emailAddress }}</v-list-item>
            <v-list-item data-cy="logout">
              <v-list-item-title @click="signOut()">Log out</v-list-item-title>
            </v-list-item>
            <v-list-item data-cy="usermanual">
              <v-list-item-title>
                <a
                  style="text-decoration: none; color: rgba(0, 0, 0, 0.87)"
                  href="https://dev.azure.com/Friss/Friss/_wiki/wikis/Friss.wiki/1109/User-Manual"
                  target="_blank"
                  >User Manual</a
                >
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<style>
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>

<script>
import mainAuth from "@/auth";
import { API } from "@/datahub-api";
export default {
  name: "appheader",
  data() {
    return {
      isSignout: false,
    };
  },
  watch: {
    async isAuthenticated(val) {
      if (val) {
        await this.setUserAuthorization();
      }
      if (!val && !this.isSignout) {
        mainAuth.signOut();
      }
    },
  },
  async created() {
    if (!this.isAuthenticated) {
      return;
    }
    this.setUserAuthorization();
  },
  computed: {
    isAuthenticated() {
      return mainAuth.isAuthenticated;
    },
    emailAddress() {
      return mainAuth.userProfile[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
      ];
    },
  },
  methods: {
    signOut() {
      this.isSignout = true;
      mainAuth.signOut();
    },
    async setUserAuthorization() {
      let response = await API.userService.canModifyData();
      if (response.isSuccess) {
        this.$permissions.update(response.result);
      }
    },
  },
};
</script>
