<template>
  <card-with-switch-component
    :isOpen="proxy.isEnabled"
    :title="`Enable proxy server for ${stage.toLowerCase()} stage`"
    :hint="`Configure web proxy for '${stage || ''}' stage`"
    @change="(value) => (proxy.isEnabled = value)"
    data-cy="proxyEnabled"
  >
    <v-row>
      <v-col cols="6">
        <v-text-field
          data-cy="proxyServer"
          v-model="proxy.server"
          label="Proxy server address"
          :rules="rules.required"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          data-cy="proxyPort"
          v-model="proxy.port"
          label="Proxy server port"
          type="number"
          :rules="rules.number"
        ></v-text-field>
      </v-col>
    </v-row>
  </card-with-switch-component>
</template>
<script>
import { Validators } from "@/helpers";
export default {
  components: {},
  props: {
    proxy: {},
    stage: String,
  },
  data: () => ({
    rules: {
      required: Validators.Required.Text,
      number: Validators.Required.Number,
    },
  }),
};
</script>
