<template>
  <v-card class="mx-auto" outlined>
    <v-app-bar>
      <h2>Certificates</h2>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          data-cy="search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-app-bar>
    <v-data-table
      dense
      :headers="headers"
      :items="certificates"
      :items-per-page="15"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <tooltip message="Edit">
          <friss-icon
            data-cy="certificate-edit"
            class="mr-3"
            @click="editCertificate(item)"
            name="edit"
          ></friss-icon>
        </tooltip>
        <confirm
          v-if="$permissions.canModifyCertificate"
          title="Delete certificate"
          yesText="Delete"
          noText="Cancel"
          class="mr-3"
          :message="'Are you sure you want to delete certificate ' + item.name + '?'"
          @confirmed="deleteItem(item.id)"
        >
          <tooltip message="Delete">
            <friss-icon data-cy="delete" name="delete"></friss-icon>
          </tooltip>
        </confirm>
      </template>
    </v-data-table>
    <div style="display: flex; padding: 20px; justify-content: end">
      <v-btn
        v-if="$permissions.canModifyCertificate"
        data-cy="certificate-add"
        color="secondary"
        outlined
        rounded
        @click="editCertificate(null)"
      >
        Add certificate
      </v-btn>
    </div>
    <certificateDialog
      v-if="showDialog"
      @saved="getAll"
      @close="showDialog = false"
      :model="certificateModel"
    >
    </certificateDialog>
  </v-card>
</template>
<script>
import tooltip from "@/components/tooltip.vue";
import certificateDialog from "./create-edit-certificate.vue";
import confirm from "@/components/confirm.dialog.vue";
import moment from "moment";
import { API } from "@/datahub-api";

export default {
  components: {
    tooltip,
    certificateDialog,
    confirm,
  },
  data() {
    return {
      showDialog: false,
      certificateModel: null,
      search: "",
      headers: [
        { text: "Data Provider", value: "dataProviderName" },
        { text: "Certificate", value: "name" },
        { text: "File name", value: "fileName" },
        { text: "Expiration date", value: "expirationDate" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
          align: "right",
        },
      ],
      certificates: [],
    };
  },
  async mounted() {
    await this.getAll();
    this.search = this.$route.query.certificateName || "";
  },
  methods: {
    async getAll() {
      let response = await API.certificateService.getAll();

      if (response.isFailure) {
        return;
      }

      const certificates = (response.result || []).map((cert) => ({
        ...cert,
        expirationDate: this.formatDate(cert.expirationDate),
      }));

      this.certificates = certificates;
    },
    async deleteItem(id) {
      await API.certificateService.delete(id);
      await this.getAll();
    },
    formatDate(dateTime) {
      if (!dateTime) {
        return null;
      }

      return moment(dateTime).local().format("DD MMM YYYY");
    },
    editCertificate(certificateDto) {
      this.certificateModel = certificateDto ? { ...certificateDto } : null;
      this.showDialog = true;
    },
  },
};
</script>
