<template>
  <div>
    <v-text-field
      :type="showValue ? 'text' : 'password'"
      v-model="model"
      :label="label"
      :data-cy="dataCy"
      :rules="rules"
      @input="onChange($event)"
      :readonly="readonly"
    >
      <template v-slot:append>
        <tooltip
          message="An encrypted value is present and it can not be copied"
          v-if="isEncrypted"
        >
          <v-icon>mdi-lock</v-icon>
        </tooltip>
        <tooltip :message="showValue ? 'Hide' : 'Show'" v-else>
          <v-icon data-cy="toggle-show" @click="showValue = !showValue">
            <template v-if="showValue"> mdi-eye </template>
            <template v-else> mdi-eye-off </template>
          </v-icon>
        </tooltip>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EncryptedInput extends Vue {
  @Prop() value: string;
  @Prop() label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: () => [] }) rules: Array<Function>;
  @Prop() dataCy: string;
  @Prop({ default: false }) readonly: boolean;

  model = "";
  showValue = false;

  private isInputFromUser = false;

  created() {
    this.model = this.value;
  }

  onChange(value: string | null) {
    this.isInputFromUser = true;
    this.$emit("input", value);
  }

  get isEncrypted() {
    return !this.isInputFromUser && this.model;
  }
}
</script>
