<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ attrs }">
      <span v-bind="attrs" @click.stop="openDialog()">
        <slot></slot>
      </span>
    </template>
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="no" @click="no()" color="secondary" rounded outlined>{{
          noMessage
        }}</v-btn>
        <v-btn data-cy="yes" color="primary" rounded @click="yes()">{{
          yesMessage
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirm",
  props: ["message", "title", "disabled", "yesText", "noText"],
  data() {
    return {
      dialog: false,
      yesMessage: this.yesText || "Yes",
      noMessage: this.noText || "No",
    };
  },
  methods: {
    yes: function () {
      this.dialog = false;
      this.$emit("confirmed");
    },
    no: function () {
      this.dialog = false;
      this.$emit("declined");
    },
    openDialog: function () {
      if (this.disabled) {
        return;
      }
      this.dialog = true;
    },
  },
};
</script>
