<template>
  <v-card>
    <v-card-text>
      <h4>Cache configuration</h4>
      <v-row>
        <v-col md="6">
          <v-checkbox
            data-cy="cache"
            v-model="model.cacheResponse"
            label="Enable cache"
            :readonly="readonly"
            @change="onCacheResponseChanged"
          ></v-checkbox>
        </v-col>
        <v-col md="6">
          <v-text-field
            v-if="model.cacheResponse"
            data-cy="lifetime"
            type="number"
            :value="cacheResponseDurationDisplayValue"
            @change="updateCacheResponseDurationInMinutes"
            label="Cache duration"
            :readonly="readonly"
            :rules="rules.cacheResponseDurationInMinutes"
            persistent-hint
            :hint="cacheResponseHint"
          >
            <template v-slot:append>
              <tooltip
                message="When the cache duration is less than 24 hours, the response from the vendor will be saved in a memory cache and will NOT be persisted into any permanent storage"
              >
                <v-icon>mdi-information</v-icon>
              </tooltip>
            </template>
            <template v-slot:append-outer>
              <v-select
                style="margin-top: -15px"
                :disabled="!model.cacheResponse"
                v-model="selectedCacheUnit"
                :items="cacheUnits"
                @change="onSelectedCacheUnitChanged"
                label="Unit"
              ></v-select>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <h4>Data retention</h4>
      <v-row>
        <v-col md="12">
          <v-text-field
            data-cy="cleanup"
            type="number"
            v-model="model.cleanUpPeriodInDays"
            label="Clears request and response blobs after (days)"
            :readonly="readonly"
            :rules="rules.cleanUpPeriodInDays"
          >
            <template v-slot:append>
              <tooltip
                message="The request sent to the vendor and the response will be stored in a blob to investigate the data exchange between the vendor and DataHub. This value represents the number of days after which the blobs will be deleted. Note: when using in-memory cache the request and response will only be saved in case of errors."
              >
                <v-icon>mdi-information</v-icon>
              </tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { ConnectionConfiguration } from "@/datahub-api";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Validators } from "@/helpers";

@Component
export default class ConnectionConfigurationComponent extends Vue {
  @Prop() model: ConnectionConfiguration;
  @Prop() dataSourceId: string;
  @Prop({ default: false }) readonly: boolean;

  cacheUnits = ["Minutes", "Hours", "Days"];
  selectedCacheUnit = "Days";
  cacheResponseDurationDisplayValue = 0;

  get cacheResponseHint() {
    if (
      !!this.model.cacheResponseDurationInMinutes &&
      this.model.cacheResponseDurationInMinutes < 60 * 24
    ) {
      // less than a day
      return "In-memory cache will be used";
    } else {
      return "";
    }
  }

  mounted() {
    if (!this.model.cacheResponseDurationInMinutes) {
      return;
    }

    this.inferSelectedUnit();
  }

  updateCacheResponseDurationInMinutes(value: string | number) {
    this.cacheResponseDurationDisplayValue = Number(value);

    switch (this.selectedCacheUnit) {
      case "Minutes":
        this.model.cacheResponseDurationInMinutes = Number(
          this.cacheResponseDurationDisplayValue
        );
        break;
      case "Hours":
        this.model.cacheResponseDurationInMinutes =
          Number(this.cacheResponseDurationDisplayValue) * 60;
        break;
      case "Days":
        this.model.cacheResponseDurationInMinutes =
          Number(this.cacheResponseDurationDisplayValue) * 60 * 24;
        break;
    }
  }

  get rules() {
    return {
      cacheResponseDurationInMinutes: this.model.cacheResponse
        ? [
            Validators.Numbers.Required,
            Validators.Numbers.Min(1),
            Validators.Numbers.Max(3650 * 6 * 24), // 10 years
          ]
        : [],
      cleanUpPeriodInDays: [
        Validators.Numbers.Required,
        Validators.Numbers.Min(1),
        Validators.Numbers.Max(3650), // 10 years
      ],
    };
  }

  onCacheResponseChanged() {
    if (this.model.cacheResponse) {
      this.model.cacheResponseDurationInMinutes ??= 60 * 24;
      this.inferSelectedUnit();
    } else {
      this.model.cacheResponseDurationInMinutes = null;
    }
  }

  onSelectedCacheUnitChanged() {
    if (
      this.model.cacheResponseDurationInMinutes === null ||
      this.model.cacheResponseDurationInMinutes === undefined
    ) {
      return;
    }

    this.updateCacheResponseDurationInMinutes(this.cacheResponseDurationDisplayValue);
  }

  private inferSelectedUnit() {
    if (!this.model.cacheResponseDurationInMinutes) {
      return;
    }

    if (this.model.cacheResponseDurationInMinutes >= 60 * 24) {
      this.selectedCacheUnit = "Days";
      this.cacheResponseDurationDisplayValue = Number(
        this.model.cacheResponseDurationInMinutes / 60 / 24
      );
    } else if (this.model.cacheResponseDurationInMinutes >= 60) {
      this.selectedCacheUnit = "Hours";
      this.cacheResponseDurationDisplayValue = Number(
        this.model.cacheResponseDurationInMinutes / 60
      );
    } else {
      this.selectedCacheUnit = "Minutes";
      this.cacheResponseDurationDisplayValue = Number(
        this.model.cacheResponseDurationInMinutes
      );
    }

    // fallback to a different unit if the value is not an integer: try first using hours than minutes
    if (!Number.isInteger(this.cacheResponseDurationDisplayValue)) {
      this.selectedCacheUnit = "Hours";
      this.cacheResponseDurationDisplayValue = Number(
        this.model.cacheResponseDurationInMinutes / 60
      );
    }
    if (!Number.isInteger(this.cacheResponseDurationDisplayValue)) {
      this.selectedCacheUnit = "Minutes";
      this.cacheResponseDurationDisplayValue = Number(
        this.model.cacheResponseDurationInMinutes
      );
    }
  }
}
</script>
