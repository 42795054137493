<template>
  <div style="width: 100%">
    <v-row>
      <v-col md="6" data-cy="authentication">
        <v-select
          color="secondary"
          :items="authenticationMethods"
          v-model="authenticationMethod"
          label="Authentication Method"
          :readonly="isFrissDataService"
          hint="How to authenticate, will affect the connection creation UI"
          persistent-hint
        >
          <template v-slot:append>
            <tooltip :message="authInfo" v-if="authInfo">
              <v-icon>mdi-information</v-icon>
            </tooltip>
          </template>
        </v-select>
      </v-col>
      <v-col v-if="isCustomAuth" md="6" data-cy="customAuth">
        <v-select
          :items="$lookupService.customAuthentications"
          v-model="model.authenticationMethod"
          label="Custom Authentication"
        ></v-select>
      </v-col>
      <v-col v-if="isAuthCertificate" md="6" data-cy="signMethod">
        <v-select
          :items="$lookupService.signingMethods"
          v-model="model.signingMethod"
          label="Signing Method"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="showAuthUri">
      <v-col
        cols="6"
        v-for="dataSourceStage in dataSource.configuration.stages"
        :key="dataSourceStage.stage"
      >
        <v-text-field
          data-cy="tokenuri"
          v-model="dataSourceStage.tokenUri"
          :label="dataSourceStage.name + ' authentication  url'"
          :hint="
            'Example: https://identity.' +
            dataSourceStage.name.toLowerCase() +
            '.vendor.com/connect/token'
          "
          :readonly="isFrissDataService"
          persistent-hint
          :rules="rules.url"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import constants from "@/services/constants";
import tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";
import { AuthenticationMethod, ResponseType, Stage } from "@/datahub-api";

export default {
  components: {
    tooltip,
  },
  props: {
    model: {
      params: [],
    },
    dataSource: {},
    isFrissDataService: Boolean,
  },
  data() {
    return {
      authInfo: "",
      rules: {
        url: Validators.Required.Url,
      },
      constants,
    };
  },
  computed: {
    authenticationMethod: {
      get() {
        if (this.isCustomAuth) {
          return constants.authentications.customAuth;
        }
        return this.model.authenticationMethod;
      },
      set(val) {
        if (val !== constants.authentications.customAuth) {
          this.model.authenticationMethod = val;
        } else {
          this.model.authenticationMethod =
            this.$lookupService.customAuthentications[0].value;
        }
      },
    },
    isCustomAuth() {
      return this.$lookupService.customAuthentications.some(
        (e) => e.value === this.model.authenticationMethod
      );
    },
    isAuthCertificate() {
      return this.model.authenticationMethod === constants.authentications.certificate;
    },
    isNewDataSource() {
      return this.dataSource.id === constants.guid.empty;
    },
    authenticationMethods() {
      if (this.isFrissDataService && this.isNewDataSource) {
        return this.$lookupService.authenticationMethods.filter(
          (method) => method.value === AuthenticationMethod.BearerToken
        );
      } else {
        return this.$lookupService.authenticationMethods;
      }
    },
    showAuthUri() {
      return (
        this.authenticationMethod === constants.authentications.bearerToken ||
        this.authenticationMethod === constants.authentications.customToken ||
        this.authenticationMethod === constants.authentications.cookieAuth
      );
    },
  },
  watch: {
    authenticationMethod(authMethod) {
      switch (authMethod) {
        case constants.authentications.bearerToken:
          this.authInfo =
            "Bearer authentication is an HTTP authentication scheme that involves security tokens. The client must send this token in the Authorization header when making requests to protected resources: Authorization: Bearer <token>.";
          break;
        case constants.authentications.basicAuth:
          this.authInfo =
            "HTTP basic authentication is a simple challenge and response mechanism with which a server can request authentication information (a user ID and password) from a client. The client passes the authentication information to the server in an Authorization header. The authentication information is in base-64 encoding.";
          break;
        case constants.authentications.certificate:
          this.authInfo =
            "Certificate-based authentication is the use of a Digital Certificate to identify a user, machine, or device before granting access to a resource, network, application, etc.";
          break;
        case constants.authentications.apiKey:
          this.authInfo =
            "An application programming interface key (API key) is a unique identifier used to authenticate a user, developer, or calling program to an API. ";
          break;
        case constants.authentications.customTemplate:
          this.authInfo =
            "Custom template based authentication expects authentication info (a user ID, password, code, etc) in the request body using convention $connection.userId$";
          break;
        case constants.authentications.customToken:
          this.authInfo =
            "Custom token authentication is an HTTP authentication scheme that involves security tokens. The client must send this token in the Authorization or Custom header when making requests to protected resources";
          break;
        case constants.authentications.customAuth:
          this.authInfo =
            "Custom authentication is an HTTP authentication scheme with custom logic";
          break;
        case constants.authentications.cookieAuth:
          this.authInfo =
            "A Cookie-based authentication uses the HTTP cookies to authenticate the client requests";
          break;
        default:
          this.authInfo = "";
          break;
      }
    },
    model: {
      handler: function (newVal) {
        if (newVal.responseType === ResponseType.Raw) {
          this.model.asyncOptions = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    onConfigurationChanged(dataSourceConfiguration) {
      this.$emit("onConfigurationChanged", {
        ...this.dataSource,
        configuration: dataSourceConfiguration,
      });
    },
  },
};
</script>
