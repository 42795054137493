export * from "./datahub-api.generated";

import { CachedTenantService } from "./cached-tenants.service";
// Export service instances
import {
  DataSourceService,
  ConnectionService,
  UserService,
  InvokeService,
  ExpressionService,
  CertificateService,
  DataProviderService,
  DataSourceResponseService,
} from "./datahub-api.generated";

export const API = {
  dataSourceService: new DataSourceService(),
  connectionService: new ConnectionService(),
  cachedTenantService: new CachedTenantService(),
  userService: new UserService(),
  invokeService: new InvokeService(),
  expressionService: new ExpressionService(),
  certificateService: new CertificateService(),
  dataProviderService: new DataProviderService(),
  dataSourceResponseService: new DataSourceResponseService(),
};
