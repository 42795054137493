<template>
  <v-card>
    <v-card-text>
      <h4>Aggregations</h4>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <td width="10%" class="text-left">Aggregator</td>
              <td width="15%" class="text-left">Path</td>
              <td width="10%" class="text-left">Label</td>
              <td width="5%" class="text-left">Is PII</td>
              <td width="60%" class="text-center">Is Valid</td>
              <td class="text-left">
                <tooltip message="Add header">
                  <v-icon @click="add()" data-cy="aggregationadd">mdi-plus</v-icon>
                </tooltip>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in aggregations" :key="i">
              <td>
                <v-select
                  data-cy="aggregation"
                  :items="$lookupService.aggregations"
                  v-model="item.aggregationMethod"
                  :rules="rules.aggregation"
                ></v-select>
              </td>
              <td>
                <v-text-field
                  v-if="!schema || schema.length === 0"
                  v-model="item.path"
                  data-cy="aggregationpath"
                  :rules="rules.path"
                ></v-text-field>

                <v-combobox
                  v-else
                  v-model="item.path"
                  :items="schema.map((x) => x.path)"
                  data-cy="aggregationpath"
                  item-text="path"
                  item-value="path"
                  :rules="rules.path"
                  clearable
                ></v-combobox>
              </td>
              <td>
                <v-text-field
                  v-model="item.label"
                  data-cy="aggregationoutput"
                  :rules="rules.label"
                ></v-text-field>
              </td>
              <td>
                <v-checkbox data-cy="piidata" v-model="item.isPiiData"></v-checkbox>
              </td>
              <td>
                <aggrWhereComp :wheres="item.wheres" />
              </td>
              <td>
                <tooltip message="Delete">
                  <friss-icon
                    data-cy="paramdelete"
                    v-if="$permissions.canModifyDataSource"
                    @click="remove(i)"
                    name="delete"
                  ></friss-icon>
                </tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import aggrWhereComp from "@/views/expression/expression.aggregation.where.vue";
import { Validators } from "@/helpers";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Aggregation, AggregationMethod } from "@/datahub-api";
import { FlattenedSchemaItem } from "@/lib/functions";

@Component({
  components: {
    aggrWhereComp,
  },
})
export default class ExpressionAggregationComponent extends Vue {
  @Prop() aggregations: Aggregation[];
  @Prop() schema: FlattenedSchemaItem[] | null;

  rules = {
    aggregation: Validators.Required.Value,
    path: Validators.Required.Text,
    label: Validators.Required.Text,
  };

  add() {
    const item: Aggregation = {
      path: "",
      label: "",
      wheres: [],
      aggregationMethod: AggregationMethod.First,
      isPiiData: false,
    };
    this.aggregations.push(item);
  }
  remove(index: number) {
    this.aggregations.splice(index, 1);
  }
}
</script>
