<template>
  <v-dialog v-model="showDialog" persistent :max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-card class="mx-auto" outlined>
      <v-card-title>
        <h2>Copy expression</h2>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12">
              <p>Copy '{{ expression.name }}' to:</p>
              <v-form ref="form">
                <v-select
                  v-model="targetDataSourceIds"
                  :items="dataSources"
                  label="Select"
                  item-text="name"
                  item-value="id"
                  multiple
                  hint="Select the data sources where you want the expression to be copied over"
                  persistent-hint
                  :rules="rules"
                  data-cy="destination"
                ></v-select>
              </v-form>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn data-cy="cancel" color="secondary" rounded outlined @click="cancel"
          >Cancel</v-btn
        >
        <v-btn data-cy="copy-to" color="primary" rounded @click="copyTo">Copy over</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { API, ExpressionDomain, ExpressionListModel } from "@/datahub-api";
import { Vue, Component, Prop } from "vue-property-decorator";
import eventHub from "@/eventhub";
import { Validators } from "@/helpers";
import { VuetifyForm } from "@/plugins";
import constants from "@/services/constants";

@Component
export default class CopyExpressionToDataSourcesComponent extends Vue {
  @Prop() expression: ExpressionListModel;
  @Prop() currentDataProviderId: string;
  @Prop() currentDataSourceId: string;

  targetDataSourceIds: Array<string> = [];
  dataSources: Array<DataSourceSelectModel> = [];

  showDialog = true;
  rules = [Validators.Array.Min(1)];

  $refs: {
    form: VuetifyForm;
  };

  async created() {
    const dataSources = await API.dataProviderService.getDataSourcesByDataProviderId(
      this.currentDataProviderId
    );

    if (dataSources.isFailure) {
      eventHub.$emit("notification", `Can not fetch the list of data source`);
      return;
    }

    const currentDataSource = dataSources.result.find(
      (d) => d.id === this.currentDataSourceId
    )!;

    const ds: DataSourceSelectModel[] = [];
    ds.push({ header: "Current data source", divider: true });
    ds.push({ id: currentDataSource.id, name: currentDataSource.name });
    ds.push({ header: "Data sources in same data provider", divider: true });
    ds.push(
      ...dataSources.result
        .filter((d) => d.id !== this.currentDataSourceId)
        .map((d) => ({ id: d.id, name: d.name }))
    );

    this.dataSources = ds;
  }

  async copyTo() {
    if (!this.$refs.form.validate()) {
      eventHub.$emit("notification", constants.clientValidation.defaultMessage);
      return;
    }

    const copyResult = await API.expressionService.copyToDataSources(
      this.expression.id,
      this.targetDataSourceIds
    );

    if (copyResult.isFailure) {
      // eventHub.$emit("notification", constants.clientValidation.defaultMessage);
      return;
    }

    eventHub.$emit("notification", "Expression has been successfully copied");
    this.$emit("copied");
  }

  cancel() {
    this.$emit("close");
  }
}

interface DataSourceSelectModel {
  id?: string;
  name?: string | undefined | null;
  header?: string;
  divider?: boolean;
}
</script>
