<template>
  <v-dialog v-model="showDialog" persistent :max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-card class="mx-auto" outlined>
      <v-app-bar>
        <h2>Apply expressions</h2>
        <v-spacer></v-spacer>
        <tooltip message="Add">
          <v-btn class="mx-2" small fab color="primary" @click="addEmptyResponse">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </tooltip>
      </v-app-bar>
      <v-tabs slider-color="accent" show-arrows v-model="activeTabIndex">
        <v-tab v-for="(response, index) in models" :key="index">
          <label class="v-label theme--light">{{ response.name }}</label>
          <label v-if="isModified(response)" class="v-label red--text text--darken-4"
            >*</label
          >
        </v-tab>
        <v-tab-item v-for="(response, index) in models" :key="index">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="response.name"
                    label="Name"
                    maxlength="100"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea filled label="Api Response" v-model="response.response">
                  </v-textarea
                  ><v-btn
                    @click="applyResponse(response)"
                    :disabled="isEmpty(response)"
                    color="secondary"
                    rounded
                    outlined
                    >Apply</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <pre>{{ response.expressionResult }}</pre>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="close" color="secondary" rounded outlined>Close</v-btn>
              <confirm
                v-if="$permissions.canModifyDataSource"
                title="Have you anonymized the data?"
                message="If this is not a Test response, please make sure there is no PII within the response. If PII persists, please anonymize the data before saving."
                @confirmed="saveResponse(response)"
                :disabled="!canSave(response)"
              >
                <v-btn
                  :disabled="!canSave(response)"
                  style="margin: 8px"
                  color="primary"
                  rounded
                  >Save</v-btn
                >
              </confirm>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
import confirm from "@/components/confirm.dialog.vue";
import tooltip from "@/components/tooltip.vue";
import { API } from "@/datahub-api";

export default {
  name: "applyExpressions",
  components: {
    confirm,
    tooltip,
  },
  props: ["datasourceId"],
  data() {
    return {
      activeTabIndex: null,
      showDialog: false,
      models: [],
    };
  },
  watch: {
    showDialog: async function (value) {
      if (value && this.models.length === 0) {
        await this.getDataSourceResponses();
      }
    },
  },
  methods: {
    createResponseViewModel(id, name, response, storedResponse, storedName) {
      var model = {
        name: name,
        response: response,
        storedResponse: storedResponse,
        storedName: storedName,
        expressionResult: "",
      };
      if (id) {
        model.id = id;
      }
      return model;
    },
    async getDataSourceResponses() {
      var response = await API.dataSourceService.getDataSourceResponses(
        this.datasourceId
      );
      if (response.isSuccess && response.result) {
        for (const r of response.result) {
          this.models.push(
            this.createResponseViewModel(r.id, r.name, r.response, r.response, r.name)
          );
        }

        if (this.models.length === 0) {
          this.addEmptyResponse();
        }
      }
    },
    async applyResponse(response) {
      response.expressionResult = "";

      const result = await API.expressionService.apply({
        response: response.response,
        dataSourceId: this.datasourceId,
      });

      response.expressionResult = result.result;
    },
    async saveResponse(response) {
      this.confirm = false;
      var result = await API.dataSourceResponseService.save({
        id: response.id,
        datasourceid: this.datasourceId,
        name: response.name,
        response: response.response,
      });
      if (result.isSuccess) {
        response.storedName = response.name;
        response.storedResponse = response.response;
      }
    },
    addEmptyResponse() {
      var model = this.createResponseViewModel(
        null,
        "API response " + (this.models.length + 1),
        "",
        "",
        ""
      );
      this.models.push(model);
    },
    isModified(response) {
      return (
        response.response !== response.storedResponse ||
        response.name !== response.storedName
      );
    },
    canSave(response) {
      return this.isModified(response) && !this.isEmpty(response);
    },
    isEmpty(response) {
      return response.response === "";
    },
    close() {
      if (!this.$permissions.canModifyDataSource) {
        this.closeAndReset();
      }
      var unsavedResponses = this.models.filter((x) => this.isModified(x));
      if (unsavedResponses.length > 0) {
        this.$root.$alert(
          "There are API responses that were not saved. Closing the dialog will revert all the unsaved changes.",
          {
            width: 320,
            header: "Discard changes?",
            yesNo: true,
            yesText: "Discard",
            noText: "Cancel",
            callback: this.alertCallback,
          }
        );
        return;
      }
      this.closeAndReset();
    },
    alertCallback(result) {
      if (result.accepted) {
        this.closeAndReset();
      } else if (!this.isModified(this.models[this.activeTabIndex])) {
        this.activeTabIndex = this.models.findIndex((x) => this.isModified(x));
      }
    },
    closeAndReset() {
      this.showDialog = false;
      this.models = [];
    },
  },
};
</script>
