<template>
  <div>
    <div
      v-if="isStripNullOrEmptyParamsFlagAvailable"
      data-cy="stripNullOrEmptyParams"
      style="margin-left: 10px"
    >
      <tooltip
        message="Removes null or empty parameters provided by KB from the JSON body before sending the request to the target Uri. Does not apply to query string parameters."
      >
        <v-checkbox
          v-model="dataSource.configuration.stripNullOrEmptyParametersFromJsonRequestBody"
          label="Strip null or empty params from JSON request body"
        ></v-checkbox>
      </tooltip>
    </div>
    <v-simple-table>
      <template>
        <thead>
          <tr>
            <td>Name</td>
            <td>Required</td>
            <td>Ignore For Caching</td>
            <td>
              <tooltip message="Add" v-if="$permissions.canModifyDataSource">
                <v-icon
                  data-cy="addparameter"
                  v-if="$permissions.canModifyDataSource"
                  @click="add()"
                  >mdi-plus</v-icon
                >
              </tooltip>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in dataSource.configuration.params" :key="i">
            <td>
              <v-text-field
                :id="'paramname-' + i"
                data-cy="paramname"
                v-model="item.name"
                :rules="rules.name"
              ></v-text-field>
            </td>
            <td>
              <v-checkbox :id="'required-' + i" v-model="item.isRequired"></v-checkbox>
            </td>
            <td>
              <v-checkbox
                :id="'ignorecaching-' + i"
                v-model="item.ignoreForCaching"
              ></v-checkbox>
            </td>
            <td>
              <tooltip message="Delete">
                <friss-icon
                  data-cy="paramdelete"
                  v-if="$permissions.canModifyDataSource"
                  @click="remove(i)"
                  name="delete"
                ></friss-icon>
              </tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div style="margin-top: 10px; float: right">
      <confirm
        title="Infer params?"
        yesText="Yes"
        noText="Cancel"
        message="The action will override existing parameters. Are you sure you want to continue?"
        @confirmed="inferParams()"
      >
        <tooltip
          message="This will pre-populate the parameters with the values taken from request query string and request body"
          v-if="$permissions.canModifyDataSource"
        >
          <!-- <v-icon data-cy="infer-params" style="margin-left: 10px"
                    >mdi-redo</v-icon
                  > -->

          <v-btn color="secondary" outlined rounded small>Populate from request</v-btn>
        </tooltip>
      </confirm>
    </div>
  </div>
</template>
<script lang="ts">
import tooltip from "@/components/tooltip.vue";
import Confirm from "@/components/confirm.dialog.vue";
import { DataSourceDto } from "@/datahub-api";
import { Validators } from "@/helpers";
import CardWithSwitchComponent from "@/components/card-with-switch.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import constants from "@/services/constants";

@Component({
  components: {
    tooltip,
    CardWithSwitchComponent,
    Confirm,
  },
})
export default class DataSourceRetryComponent extends Vue {
  @Prop() dataSource: DataSourceDto;

  rules = {
    name: Validators.Required.Text,
  };

  panelModel: [number?] = [];

  add() {
    let param = { name: "", isRequired: false, ignoreForCaching: false };
    this.dataSource.configuration!.params!.push(param);
  }
  remove(index: number) {
    this.dataSource.configuration!.params!.splice(index, 1);
  }
  expandPanel() {
    this.panelModel = [0];
  }

  inferParams() {
    const paramRegex = /\$[\w]+\$/g;
    const paramsMatch = (
      (this.dataSource.configuration?.stages?.map((s) => s.dataSourceUri).join() || "") +
      (this.dataSource.queryString || "") +
      (this.dataSource.requestBody || "")
    ).matchAll(paramRegex);

    if (!paramsMatch) {
      return;
    }

    this.dataSource.configuration!.params!.splice(
      0,
      this.dataSource.configuration!.params!.length
    );

    for (const [paramWithDelimiters] of paramsMatch) {
      const param = paramWithDelimiters.slice(1, -1); // remove start and end $ sign

      this.dataSource.configuration!.params!.push({
        name: param,
        isRequired: true,
        ignoreForCaching: false,
      });
    }
  }

  created() {
    if (
      this.dataSource.id === constants.guid.empty &&
      this.isStripNullOrEmptyParamsFlagAvailable
    ) {
      this.dataSource.configuration!.stripNullOrEmptyParametersFromJsonRequestBody = true;
    }
  }

  get isStripNullOrEmptyParamsFlagAvailable() {
    return (
      (this.dataSource.configuration!.contentType === constants.contentTypes.json &&
        this.dataSource.requestBody?.length) ||
      0 > 0
    );
  }
}
</script>
