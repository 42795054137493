export * from "./dataservice-api.generated";
import {
  SearchService,
  SchemaService,
  TenantsService,
  DataSourceService,
} from "./dataservice-api.generated";

export const DATASERVICE_API = {
  dataSourceService: new DataSourceService(),
  SearchService: new SearchService(),
  tenantsService: new TenantsService(),
  schemaService: new SchemaService(),
};
