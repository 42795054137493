<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <span>{{message}}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "tooltip",
  props: ["message"]
};
</script>