<template>
  <v-card class="mx-auto" outlined>
    <v-app-bar>
      <h2>Connections</h2>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          data-cy="search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-app-bar>
    <v-data-table
      dense
      :headers="headers"
      :items="connections"
      :items-per-page="15"
      :search="search"
      data-cy="connections-table"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
        <copy :content="item.id" />
      </template>
      <template v-slot:[`item.dataSourceVersion`]="{ item }">
        <span :data-datasource-version="item.dataSourceVersion">{{
          item.dataSourceVersion | date
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <tooltip :message="item.canEdit ? 'Edit' : 'View'">
          <friss-icon
            data-cy="edit"
            class="mr-3"
            @click="editConnection(item.id)"
            name="edit"
          ></friss-icon>
        </tooltip>

        <confirm
          v-if="$permissions.canModifyConnection"
          title="Delete connection"
          message="Are you sure you want to to delete the connection?"
          yesText="Delete"
          noText="Cancel"
          @confirmed="deleteItem(item.id)"
          :disabled="!item.canEdit"
        >
          <tooltip message="Delete">
            <friss-icon
              data-cy="delete"
              name="delete"
              :disabled="!item.canEdit"
            ></friss-icon>
          </tooltip>
        </confirm>
      </template>
    </v-data-table>

    <div
      v-if="$permissions.canModifyConnection"
      style="display: flex; padding: 20px; justify-content: end"
    >
      <v-btn color="secondary" rounded outlined data-cy="add" @click="addConnection()">
        Add connection
      </v-btn>
    </div>

    <connection
      v-if="showConnectionDialog"
      :connectionId="selectedConnectionId"
      :dataproviderId="dataproviderId"
      :datasourceId="datasourceId"
      @saved="getAll"
      @cancel="showConnectionDialog = false"
    >
    </connection>
  </v-card>
</template>
<script>
import confirm from "@/components/confirm.dialog.vue";
import tooltip from "@/components/tooltip.vue";
import connection from "@/views/connection/connection.vue";
import copy from "@/components/copy.clipboard.vue";
import { API } from "@/datahub-api";

export default {
  components: {
    confirm,
    tooltip,
    connection,
    copy,
  },
  data() {
    return {
      showConnectionDialog: false,
      selectedConnectionId: null,
      search: "",
      headers: [
        { text: "Tenant", value: "tenantName" },
        { text: "TenantId", value: "tenantId" },
        { text: "ConnectionId", value: "id" },
        { text: "Version", value: "dataSourceVersion", filterable: false },
        { text: "Stage", value: "stage", filterable: false },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "100px",
        },
      ],
      connections: [],
    };
  },
  props: ["dataproviderId", "datasourceId"],
  async mounted() {
    await this.getAll();
  },
  methods: {
    async getAll() {
      this.showConnectionDialog = false;
      let response = await API.connectionService.getAll(this.$route.query.correlationId);
      if (response.isSuccess) {
        this.connections = response.result || [];
      }
    },
    async deleteItem(id) {
      await API.connectionService.delete(id);
      await this.getAll();
    },
    editConnection(connectionId) {
      this.selectedConnectionId = connectionId;
      this.showConnectionDialog = true;
    },
    addConnection() {
      this.selectedConnectionId = null;
      this.showConnectionDialog = true;
    },
  },
};
</script>
