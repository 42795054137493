<template>
  <div>
    <v-row>
      <v-col md="4">
        <encrypted-input
          v-model="model.username"
          label="User name"
          data-cy="username"
          :rules="rules.username"
          :readonly="readonly"
        ></encrypted-input>
      </v-col>
      <v-col md="4">
        <encrypted-input
          v-model="model.clientId"
          label="Client Id"
          data-cy="clientId"
          :rules="rules.clientId"
          :readonly="readonly"
        ></encrypted-input>
      </v-col>
      <v-col md="4">
        <encrypted-input
          v-model="model.clientSecret"
          label="Client Secret"
          data-cy="clientSecret"
          :rules="rules.clientSecret"
          :readonly="readonly"
        ></encrypted-input>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Validators } from "@/helpers";

export default {
  name: "infologAuth",
  data() {
    return {
      rules: {
        username: Validators.Required.Text,
        clientId: Validators.Required.Text,
        clientSecret: Validators.Required.Text,
      },
    };
  },
  props: {
    model: {},
    readonly: Boolean,
  },
};
</script>
