import Vue from "vue";
import Router from "vue-router";
import datasources from "./views/datasource/datasources.vue";
import dataproviders from "./views/dataprovider/dataproviders.vue";
import certificates from "./views/certificates/certificates.vue";
import connections from "./views/connection/connections.vue";
import expressions from "./views/expression/expressions.vue";
import mainAuth from "./auth";

Vue.use(Router);

var router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "dataproviders",
      component: dataproviders,
      meta: {
        authName: mainAuth.authName,
        breadCrumb: (route) => route.query.dataProviderName,
      },
    },
    {
      path: "/manage-certificates/",
      name: "manage-certificates",
      props: true,
      component: certificates,
      meta: {
        authName: mainAuth.authName,
      },
    },
    {
      path: "/dataprovider/:dataproviderId/datasources",
      name: "datasources",
      component: datasources,
      props: true,
      meta: {
        authName: mainAuth.authName,
        breadCrumb: (route) => route.query.dataSourceName,
        query: () => window.location.search,
        parents: ["dataproviders"],
      },
    },
    {
      path: "/dataprovider/:dataproviderId/datasource/:datasourceId/connections",
      name: "connections",
      component: connections,
      props: true,
      meta: {
        authName: mainAuth.authName,
        parents: ["dataproviders", "datasources"],
      },
    },
    {
      path: "/dataprovider/:dataproviderId/datasource/:datasourceId/expressions",
      name: "expressions",
      component: expressions,
      props: true,
      meta: {
        authName: mainAuth.authName,
        parents: ["dataproviders", "datasources"],
      },
    },
  ],
});

mainAuth.useRouter(router);

export default router;
