import constants from "@/services/constants";

function isValidURL(str: string) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

const elasticIndexNameForbiddenChars = [
  " ",
  '"',
  "*",
  "\\",
  "<",
  "|",
  ",",
  ">",
  "/",
  "?",
  "'",
  ":",
];

export const Validators = {
  Required: {
    Value: [(val: unknown) => val != null || "This field is required"],
    ValueWithCustomMessage: (customMessage: string | boolean) => (val: unknown) => {
      return val != null
        ? true
        : typeof customMessage === "string"
        ? customMessage
        : "This field is required";
    },
    Text: [
      (val: string | null | undefined) =>
        (val || "").length > 0 || "This field is required",
    ],
    Url: [
      (val: string) =>
        ((val || "").length > 0 && isValidURL(val)) || "This URL is required",
    ],
    ElasticIndexName: [
      (val: string) =>
        ((val || "").length > 0 &&
          elasticIndexNameForbiddenChars.every(
            (forbiddenChar) => !val.includes(forbiddenChar)
          )) ||
        "This field is required and can not contain spaces or special characters",
    ],
    File: [
      (v: File | null | undefined) => !!v || "File is required",
      (v: File | null | undefined) => (v && v.size > 0) || "File is required",
    ],
    Guid: [
      (val: string | null | undefined) =>
        (val && val !== constants.guid.empty) || "This field is required",
    ],
  },
  Array: {
    Min: (minValue: number) => (val: string) =>
      val?.length >= minValue || `This field has to contain at least ${minValue} items`,
  },
  Numbers: {
    Required: (val: string | number) =>
      (val !== null && val !== undefined && val != "" && !isNaN(+val)) ||
      "This field is required",
    Min: (minValue: number) => (val: string | number) =>
      val === "" ||
      val === null ||
      val >= minValue ||
      `This value must be greater than ${minValue}`,

    Max: (maxValue: number) => (val: string | number) =>
      val === "" ||
      val === null ||
      val <= maxValue ||
      `This value must be less than ${maxValue}`,
  },
};
