<template>
  <v-card class="mx-auto">
    <v-app-bar>
      <h2 data-cy="page-title">Data providers</h2>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          data-cy="search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-app-bar>
    <v-data-table
      :headers="headers"
      :items="dataProviders"
      :items-per-page="15"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :search="search"
      dense
      ><template v-slot:[`item.name`]="{ item }">
        <div
          v-if="item.name.toLowerCase() === constants.friss"
          style="display: flex; justify-content: flex-start; align-items: center"
          class="hover-pointer"
          @click="dataSources(item.id, item.name)"
        >
          <v-icon class="mr-3" small>$frissIcon</v-icon> DataService
        </div>
        <span v-else @click="dataSources(item.id, item.name)" class="hover-pointer">{{
          item.name
        }}</span>
      </template>
      <template v-slot:[`item.links`]="{ item }">
        <span v-for="(link, i) in item.links" :key="i">
          <a :href="link.uri" target="_blank">{{ link.label }}</a> <br />
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <tooltip message="Edit">
          <friss-icon
            data-cy="edit"
            @click="isDataService(item) ? null : edit(item.id)"
            :disabled="isDataService(item)"
            class="mr-3"
            name="edit"
          ></friss-icon>
        </tooltip>
        <tooltip message="Data sources">
          <friss-icon
            data-cy="datasource"
            class="mr-3"
            @click="dataSources(item.id, item.name)"
            name="globe"
          ></friss-icon>
        </tooltip>
        <confirm
          title="Delete data provider"
          :message="'Are you sure you want to to delete ' + item.name + '?'"
          yesText="Delete"
          noText="Cancel"
          :disabled="isDataService(item)"
          @confirmed="deleteItem(item.id)"
        >
          <tooltip message="Delete">
            <friss-icon
              data-cy="delete"
              small
              name="delete"
              :disabled="isDataService(item)"
            ></friss-icon>
          </tooltip>
        </confirm>
      </template>
    </v-data-table>
    <div style="display: flex; padding: 20px; justify-content: end">
      <input
        type="file"
        style="display: none"
        ref="importFileElement"
        accept=".json"
        @change="onImportFileChanged"
      />
      <v-btn
        data-cy="import"
        class="mx-2"
        color="secondary"
        outlined
        rounded
        @click="() => $refs.importFileElement.click()"
      >
        Import data sources
      </v-btn>

      <v-btn
        v-if="$permissions.canModifyDataSource"
        class="mx-2"
        data-cy="add"
        color="secondary"
        outlined
        rounded
        @click="create()"
      >
        Add data provider
      </v-btn>
    </div>
    <dataprovider
      v-if="dataProviderId !== null"
      :dataProviderId="dataProviderId"
      @saved="getAll"
      @close="dataProviderId = null"
    />
  </v-card>
</template>
<script>
import tooltip from "@/components/tooltip.vue";
import dataprovider from "@/views/dataprovider/dataprovider.vue";
import confirm from "@/components/confirm.dialog.vue";
import constants from "@/services/constants";
import { API } from "@/datahub-api";
import eventHub from "@/eventhub";

export default {
  components: {
    tooltip,
    dataprovider,
    confirm,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Links", value: "links" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "120px",
        },
      ],
      sortBy: "",
      sortDesc: false,
      dataProviders: [],
      constants,
      dataProviderId: null,
      showImportDataSourcesDialog: false,
    };
  },
  async mounted() {
    await this.getAll();
  },
  methods: {
    async getAll() {
      let response = await API.dataProviderService.getAll();
      if (response.isSuccess) {
        this.dataProviders = response.result || [];
      }
    },
    dataSources(id, name) {
      this.$router.push({
        path: `/dataprovider/${id}/datasources`,
        query: { dataProviderName: name },
      });
    },
    async deleteItem(id) {
      await API.dataProviderService.delete(id);
      await this.getAll();
    },
    edit(dataProviderId) {
      this.dataProviderId = dataProviderId;
    },
    create() {
      this.dataProviderId = "";
    },
    isDataService(item) {
      return item.name.toLowerCase() === constants.friss;
    },
    openImportDialog() {
      this.showImportDataSourcesDialog = true;
    },
    closeImportDialog() {
      this.showImportDataSourcesDialog = false;
    },
    onImportFileChanged(changeEvent) {
      const fileReader = new FileReader();

      fileReader.onload = async () => {
        changeEvent.target.value = null;
        const jsonExport = fileReader.result;

        const importResult = await API.dataSourceService.import(JSON.parse(jsonExport));

        if (importResult.isSuccess) {
          await this.getAll();
          eventHub.$emit("success", "DataSources have been imported successfully");
        }
      };

      fileReader.readAsText(changeEvent.target.files[0]);
    },
  },
};
</script>
