import Vue from "vue";
import { createOidcAuth, SignInType, LogLevel } from "vue-oidc-client";
import runtimeconfig from "./runtime_config";

import { WebStorageStateStore } from "oidc-client";
import SessionStorage from "./sessionstorage";

function getAppRootUrl() {
  return `${window.location.protocol}//${window.location.host}${process.env.BASE_URL}`;
}

const OIDC_SETTINGS = {
  authority: runtimeconfig.authority,
  client_id: runtimeconfig.clientId,
  response_type: "id_token token",
  scope: "openid profile datahub screening",
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
  userStore: new WebStorageStateStore({ store: new SessionStorage() }),
  revokeAccessTokenOnSignout: true,
};

const mainOidc = createOidcAuth(
  "main",
  SignInType.Window,
  getAppRootUrl(),
  OIDC_SETTINGS,
  console,
  LogLevel.Info
);

Vue.prototype.$oidc = mainOidc;
(window as any).$oidc = mainOidc;

export default mainOidc;
