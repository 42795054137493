<template>
  <v-card class="mx-auto">
    <v-app-bar>
      <h2>Data Sources</h2>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          data-cy="search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-app-bar>
    <v-data-table
      v-model="selectedDataSources"
      :headers="headers"
      :items="dataSources"
      :items-per-page="15"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-expand
      :search="search"
      dense
      show-select
    >
      <template v-slot:[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length" style="padding: 35px">
          <versionedDataSources
            ref="versionedDataSource"
            :dataproviderId="dataproviderId"
            :dataSourceId="item.id"
            @onDeleted="getAll()"
          ></versionedDataSources>
        </td>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
        <friss-icon
          data-cy="expand_versions"
          tabindex="0"
          title="Show versions"
          @keyup.enter="expand(true)"
          @click="expand(true)"
          v-if="!isExpanded"
          name="chevron-down-alt-lg"
        ></friss-icon>
        <friss-icon
          data-cy="collapse_versions"
          tabindex="0"
          title="Hide versions"
          @keyup.enter="expand(false)"
          @click="expand(false)"
          v-if="isExpanded"
          name="chevron-up-alt-lg"
        ></friss-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <tooltip message="Edit">
          <friss-icon
            data-cy="edit"
            class="mr-3"
            color="secondary"
            @click="editDataSource(item.id)"
            name="edit"
          ></friss-icon>
        </tooltip>
        <tooltip message="Connections">
          <friss-icon
            data-cy="connections"
            class="mr-3"
            @click="connections(item.id, item.name, item.correlationId)"
            name="network-view"
          ></friss-icon>
        </tooltip>
        <tooltip message="Expressions">
          <friss-icon
            data-cy="expressions"
            :disabled="isbase64ResponseType(item.responseType)"
            class="mr-3"
            @click="expressions(item.id, item.name)"
            name="filter"
          ></friss-icon>
        </tooltip>
      </template>
    </v-data-table>

    <div style="display: flex; padding: 20px; justify-content: end">
      <v-btn
        :disabled="selectedDataSources.length === 0"
        class="mx-2"
        color="secondary"
        rounded
        outlined
        @click="exportSelectedDataSources()"
        data-cy="export-selection"
      >
        Export selection
      </v-btn>
      <tooltip :message="$permissions.canModifyDataSource ? 'Create new data source' : 'Direct edit is disabled. Please make your changes in a lower environment (like SPA), export the datasource file and import it here.'">
      <v-btn
        :disabled="!$permissions.canModifyDataSource"
        class="mx-2"
        color="secondary"
        rounded
        outlined
        @click="createDataSource()"
        data-cy="add"
      >
        Add data source
      </v-btn>
      </tooltip>
    </div>

    <dataSource
      v-if="selectedDataSourceId !== null"
      :datasourceId="selectedDataSourceId"
      :dataproviderId="dataproviderId"
      :isDraft="true"
      @saved="refreshTables()"
      @close="selectedDataSourceId = null"
    >
    </dataSource>
  </v-card>
</template>
<script lang="ts">
import confirm from "@/components/confirm.dialog.vue";
import versionedDataSources from "@/views/datasource/datasources.versioned.vue";
import dataSource from "@/views/datasource/datasource.vue";
import tooltip from "@/components/tooltip.vue";
import constants from "@/services/constants";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ResponseType, API, DataSourceListModel } from "@/datahub-api";

@Component({
  components: {
    confirm,
    dataSource,
    versionedDataSources,
    tooltip,
  },
})
export default class DataSources extends Vue {
  search = "";
  headers = [
    { text: "Name", value: "name" },
    { text: "Description", value: "description" },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      filterable: false,
      width: "180px",
    },
    { text: "", value: "data-table-expand" },
  ];
  sortBy = "name";
  sortDesc = false;
  dataSources: DataSourceListModel[] = [];
  selectedDataSourceId: string | null = null;
  selectedDataSources: DataSourceListModel[] = [];

  @Prop() dataproviderId: string;

  $refs: {
    versionedDataSource: Vue & {
      getAll: () => Promise<unknown>;
    };
  };

  async mounted() {
    await this.getAll();
  }

  async refreshTables() {
    this.$refs.versionedDataSource?.getAll();
    this.getAll();
  }

  async getAll() {
    let response = await API.dataProviderService.getDataSourcesByDataProviderId(
      this.dataproviderId
    );
    if (response.isSuccess) {
      this.dataSources = response.result || [];
    }
  }
  isbase64ResponseType(responseType: ResponseType) {
    return responseType === constants.responseTypes.base64;
  }
  expressions(datasourceId: string, dataSourceName: string) {
    this.$router.push({
      path: `/dataprovider/${this.dataproviderId}/datasource/${datasourceId}/expressions`,
      query: {
        dataSourceName: dataSourceName,
        dataProviderName: this.$route.query.dataProviderName,
      },
    });
  }
  connections(datasourceId: string, dataSourceName: string, correlationId: string) {
    this.$router.push({
      path: `/dataprovider/${this.dataproviderId}/datasource/${datasourceId}/connections`,
      query: {
        dataSourceName: dataSourceName,
        dataProviderName: this.$route.query.dataProviderName,
        correlationId: correlationId,
      },
    });
  }
  async deleteItem(id: string) {
    await API.dataSourceService.delete(id);
    await this.getAll();
  }
  editDataSource(id: string) {
    this.selectedDataSourceId = id;
  }
  createDataSource() {
    this.selectedDataSourceId = "";
  }

  async exportSelectedDataSources() {
    const selectedDataSourceIds = this.selectedDataSources.map((d) => d.id);
    const exportResult = await API.dataSourceService.export(selectedDataSourceIds);

    const fileContent = JSON.stringify(exportResult.result, null, 2);

    const fileName = `${window.location.hostname}-${
      exportResult.result.dataProvider.name
    }-export-${new Date().toISOString()}.json`;

    this.downloadFile(fileContent, fileName);
  }

  private downloadFile(content: string, fileName: string) {
    const blob = new Blob([content]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
</script>
