<template>
    <v-dialog v-model="showDialog" persistent :max-width="1200">
        <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
        </template>
        <v-card class="mx-auto" outlined>
            <v-app-bar>
                <h2>Connection</h2>
            </v-app-bar>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col md="4" data-cy="tenant">
                            <v-autocomplete
                                :items="tenants"
                                v-model="model.tenantId"
                                item-text="tenantName"
                                item-value="tenantId"
                                label="Tenant"
                                :rules="rules.tenantId"
                                :readonly="!hasUserAccessToTenant"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="4" data-cy="dataSources">
                            <v-select
                                :items="dataSources"
                                item-text="dataSourceCreatedDate"
                                item-value="id"
                                v-model="model.dataSourceId"
                                label="Data Source Version"
                                v-on:change="changeConnectionDataSource"
                                :rules="rules.datasourceId"
                                :readonly="!hasUserAccessToTenant"
                            ></v-select>
                        </v-col>
                        <v-col cols="4" data-cy="stage">
                            <v-select
                                :items="stages"
                                v-model="model.stage"
                                label="Stage"
                                :rules="rules.stage"
                                :readonly="!hasUserAccessToTenant"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="isCustomTemplate">
                        <v-col md="12">
                            <template>
                                <customTemplateAuth
                                    :model="model.credential"
                                    :key="model.credential.items"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col>
                    </v-row>
                    <v-row v-if="isBasic">
                        <v-col md="12">
                            <template>
                                <basicAuth
                                    :model="model.credential"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col
                        >
                    </v-row>
                    <v-row v-if="isBearer">
                        <v-col md="12">
                            <template>
                                <bearerAuth
                                    :model="model.credential"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col
                        >
                    </v-row>
                    <v-row v-if="isCertificate">
                        <v-col md="12">
                            <template>
                                <certificateAuth
                                    :connection="model"
                                    :dataProviderId="dataproviderId"
                                    data-cy="certificate-name"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col
                        >
                    </v-row>
                    <v-row v-if="isApikey">
                        <v-col md="12">
                            <template>
                                <apikeyAuth
                                    :model="model.credential"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col
                        >
                    </v-row>
                    <v-row v-if="isCustomToken">
                        <v-col md="12">
                            <template>
                                <customTokenAuth
                                    :model="model.credential"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col
                        >
                    </v-row>
                    <v-row v-if="isInfologAuth">
                        <v-col md="12"
                        >
                            <template>
                                <infologAuth
                                    :model="model.credential"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col
                        >
                    </v-row>
                    <v-row v-if="isCookie">
                        <v-col md="12">
                            <template>
                                <cookieAuth
                                    :model="model.credential"
                                    :readonly="!hasUserAccessToTenant"
                                />
                            </template>
                        </v-col
                        >
                    </v-row>

                    <v-row>
                        <v-col md="6">
                            <configuration
                                v-if="isConnectionFetched"
                                :model="model.configuration"
                                :readonly="!hasUserAccessToTenant"
                            />
                        </v-col>
                        <v-col md="6">
                            <headers
                                v-if="model.customHeaders"
                                :customHeaders="model.customHeaders"
                                v-on:updateHeaders="updateHeaders"
                                :readonly="!hasUserAccessToTenant"
                                title="Request headers"
                                data-cy="request-headers"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn data-cy="cancel" color="secondary" rounded outlined @click="cancel"
                >Cancel
                </v-btn
                >
                <v-btn
                    v-if="$permissions.canModifyConnection"
                    class="primary ml-4"
                    :disabled="!hasUserAccessToTenant"
                    data-cy="save"
                    rounded
                    @click="checkAndSave"
                >Save
                </v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import basicAuth from "@/views/connection/credentials/basic.vue";
import bearerAuth from "@/views/connection/credentials/bearer.vue";
import certificateAuth from "@/views/connection/credentials/certificate.vue";
import apikeyAuth from "@/views/connection/credentials/apikey.vue";
import customTemplateAuth from "@/views/connection/credentials/customTemplate.vue";
import customTokenAuth from "@/views/connection/credentials/customToken.vue";
import infologAuth from "@/views/connection/credentials/infologAuth.vue";
import cookieAuth from "@/views/connection/credentials/cookie.vue";
import configuration from "@/views/connection/connections.configuration.vue";
import headers from "@/views/connection/headers.vue";
import constants from "@/services/constants";
import {Validators} from "@/helpers";
import eventHub from "@/eventhub";
import {
    API,
    ConnectionDto,
    CredentialBaseDto,
    DataSourceListModel,
    KeyValuePairOfStringAndString,
    Stage,
    TenantDto,
} from "@/datahub-api";

import {Vue, Component, Prop} from "vue-property-decorator";
import {VuetifyForm} from "@/plugins";
import {formatDateTime} from "@/filters/date-filter";

@Component({
    components: {
        basicAuth,
        bearerAuth,
        certificateAuth,
        apikeyAuth,
        customTemplateAuth,
        customTokenAuth,
        infologAuth,
        cookieAuth,
        configuration,
        headers,
    },
})
export default class ConnectionComponent extends Vue {
    @Prop() dataproviderId: string;
    @Prop() datasourceId: string;
    @Prop() connectionId: string;

    showDialog = true;
    model: ConnectionDto & { credential: CredentialBaseDto } = {
        configuration: {},
        credential: {},
        customHeaders: [],
    } as any;
    tenants: Array<TenantDto & { disabled?: boolean; divider?: boolean; header?: string }> =
        [];
    myTenants: Array<TenantDto> = [];
    dataSources: Array<DataSourceListModel> = [];

    rules = {
        tenantId: Validators.Required.Guid,
        datasourceId: Validators.Required.Guid,
        stage: Validators.Required.Value,
    };

    $refs: {
        form?: VuetifyForm;
    };

    isConnectionFetched = false;

    stages: Array<{ text: string, value: Stage }> = [];

    async created() {
        await this.getConnection();
        await this.getTenants();
        await this.getDataSources();
        await this.getStages();
        this.$refs?.form?.resetValidation();
    }

    async changeConnectionDataSource(dataSourceId: string) {
        const response = await API.connectionService.add(dataSourceId);
        if (response.isSuccess) {
            const connection = response.result;
            if (this.model.credential.items) {
                connection.credential!.items!.forEach((element) => {
                    const item = this.model.credential.items!.find((x) => x.key === element.key);
                    if (item) {
                        element.value = item.value!;
                    }
                });
            }
            if (
                this.model.credential.authenticationMethod ===
                connection.credential!.authenticationMethod
            ) {
                this.model.credential.items = connection.credential!.items;
            } else {
                this.model.credential = connection.credential!;
            }
        }
    }

    async getConnection() {
        const response = this.connectionId
            ? await API.connectionService.get(this.connectionId)
            : await API.connectionService.add(this.datasourceId);
        if (response.isSuccess) {
            this.model = response.result as any;
        }
        this.isConnectionFetched = true;
    }

    async getTenants() {
        const currentUserTenants = await API.cachedTenantService.getMyTenants();

        if (currentUserTenants.isFailure) {
            return this.$emit("notification", `Error retrieving the list of user's tenants`);
        }

        this.myTenants = [...currentUserTenants.result];

        const tenants = [...currentUserTenants.result];

        if (
            this.model.tenantId &&
            this.model.tenantId != constants.guid.empty &&
            !tenants.some((t) => t.tenantId === this.model.tenantId)
        ) {
            tenants.push({
                tenantId: this.model.tenantId,
                tenantName: this.model.tenantName,
            });
        }

        this.tenants = tenants;
    }

    async getDataSources() {
        const response = await API.dataSourceService.getHistory(this.datasourceId);
        if (response.isSuccess) {
            this.dataSources = response.result.map((v) => {
                return {
                    ...v,
                    dataSourceCreatedDate: formatDateTime(v.createdDate),
                };
            });
        }
    }

    async getStages() {
        const response = await API.dataSourceService.get(this.datasourceId);
        if (response.isSuccess) {
            this.stages = response.result.configuration?.stages?.map(s => {
                return {
                    text: Stage[s.stage],
                    value: s.stage
                }
            }) || [];
        }
    }

    async checkAndSave() {
        if (!this.$refs.form?.validate()) {
            eventHub.$emit("notification", constants.clientValidation.defaultMessage);
            return;
        }

        await this.save();
    }

    async alertCallback(result: { accepted: boolean }) {
        if (result.accepted) {
            await this.save();
        }
    }

    async save() {
        this.model.dataProviderId = this.dataproviderId;
        const response = await API.connectionService.save(this.model);
        if (response.isSuccess) {
            this.showDialog = false;
            this.$emit("saved");
        }
    }

    cancel() {
        this.showDialog = false;
        setTimeout(() => {
            this.$emit("cancel");
        }, 100);
    }

    updateHeaders(customHeaders: KeyValuePairOfStringAndString[] | null) {
        this.model.customHeaders = JSON.parse(JSON.stringify(customHeaders));
    }

    get isBasic() {
        return (
            this.model.credential?.authenticationMethod === constants.authentications.basicAuth
        );
    }

    get isBearer() {
        return (
            this.model.credential?.authenticationMethod ===
            constants.authentications.bearerToken
        );
    }

    get isCertificate() {
        return (
            this.model.credential?.authenticationMethod ===
            constants.authentications.certificate
        );
    }

    get isApikey() {
        return (
            this.model.credential?.authenticationMethod === constants.authentications.apiKey
        );
    }

    get isCustomTemplate() {
        return (this.model.credential?.items?.length || 0) > 0;
    }

    get isCustomToken() {
        return (
            this.model.credential?.authenticationMethod ===
            constants.authentications.customToken
        );
    }

    get isInfologAuth() {
        return (
            this.model.credential?.authenticationMethod ===
            constants.authentications.infologAuth
        );
    }

    get isCookie() {
        return (
            this.model.credential?.authenticationMethod === constants.authentications.cookieAuth
        );
    }

    get hasUserAccessToTenant() {
        if (!this.model.tenantId || this.model.tenantId == constants.guid.empty) {
            return true;
        }

        return this.myTenants.some((t) => t.tenantId === this.model.tenantId);
    }
}
</script>
