import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import mainAuth from "./auth";
import permissions from "./permissions";
import "@/filters/date-filter.js";
import "./global-components";

import { catalogue, installFrissIcons } from "@friss/icons";

installFrissIcons();

Vue.config.productionTip = false;

Vue.use(permissions, {
  store: new permissions.Permissions(),
});

mainAuth.startup().then((ok) => {
  if (ok) {
    new Vue({
      vuetify,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
