import Vue from "vue";

export function secondsTo(
  timeIntervalInSeconds: number | string | null,
  displayAs: "seconds" | "minutes"
): number | null {
  if (!timeIntervalInSeconds) {
    return null;
  }
  switch (displayAs) {
    case "seconds":
      return +timeIntervalInSeconds;
    case "minutes":
      return +timeIntervalInSeconds / 60;
    default:
      return +timeIntervalInSeconds;
  }
}
export function minutesTo(
  timeIntervalInMinutes: number | string | null,
  displayAs: "seconds" | "minutes"
): number | null {
  if (!timeIntervalInMinutes) {
    return null;
  }

  switch (displayAs) {
    case "seconds":
      return +timeIntervalInMinutes * 60;
    case "minutes":
      return +timeIntervalInMinutes;
    default:
      return +timeIntervalInMinutes;
  }
}

Vue.filter("secondsTo", secondsTo);
Vue.filter("minutesTo", minutesTo);
