import {
  AggregationMethod,
  AuthenticationMethod,
  CompareOperator,
  FieldType,
  HttpVerb,
  ResponseType,
  SelectMethod,
  SigningMethod,
  Stage,
} from "@/datahub-api";
import constants from "@/services/constants";

export class LookupService {
  get httpVerbs() {
    return [
      { text: "Get", value: HttpVerb.Get },
      { text: "Post", value: HttpVerb.Post },
      { text: "Put", value: HttpVerb.Put },
      { text: "Delete", value: HttpVerb.Delete },
    ];
  }

  get matchingTypes() {
    return [
      { text: "", value: "" },
      { text: "PersonLastName", value: "PersonLastName" },
      { text: "PersonFullName", value: "PersonFullName" },
      { text: "CompanyName", value: "CompanyName" },
    ];
  }

  get aggregations() {
    return [
      { text: "First", value: AggregationMethod.First },
      { text: "Last", value: AggregationMethod.Last },
      { text: "Sequence", value: AggregationMethod.Sequence },
      { text: "Sum", value: AggregationMethod.Sum },
      { text: "Count", value: AggregationMethod.Count },
      { text: "Avg", value: AggregationMethod.Avg },
      { text: "Min", value: AggregationMethod.Min },
      { text: "Max", value: AggregationMethod.Max },
    ];
  }

  get types() {
    return [
      { text: "string", value: FieldType.StringType },
      { text: "decimal", value: FieldType.DecimalType },
      { text: "boolean", value: FieldType.BooleanType },
      { text: "datetime", value: FieldType.DatetimeType },
    ];
  }

  getOperators(fieldType: FieldType) {
    const comparisonOperators = [
      { text: "=", value: CompareOperator.Equal },
      { text: "<>", value: CompareOperator.IsNotEqual },
      { text: ">", value: CompareOperator.Greater },
      { text: "<", value: CompareOperator.Less },
      { text: ">=", value: CompareOperator.GreaterOrEqual },
      { text: "<=", value: CompareOperator.LessOrEqual },
    ];

    const operators: { [fieldType: string]: { text: string; value: CompareOperator }[] } =
      {
        [FieldType.DatetimeType.toString()]: [
          ...comparisonOperators,
          { text: "InLastMonths", value: CompareOperator.InLastMonths },
        ],
        [FieldType.StringType.toString()]: [
          ...comparisonOperators,
          { text: "In", value: CompareOperator.In },
          { text: "NotIn", value: CompareOperator.NotIn },
          { text: "IsEmpty", value: CompareOperator.IsEmpty },
          { text: "IsNotEmpty", value: CompareOperator.IsNotEmpty },
        ],
        [FieldType.BooleanType.toString()]: [
          { text: "=", value: CompareOperator.Equal },
          { text: "<>", value: CompareOperator.IsNotEqual },
        ],
        [FieldType.DecimalType.toString()]: [...comparisonOperators],
      };

    return operators[fieldType];
  }

  get responseType() {
    return [
      { text: "Json", value: ResponseType.Json },
      { text: "Xml", value: ResponseType.Xml },
      { text: "Base64", value: ResponseType.Base64 },
      { text: "Raw", value: ResponseType.Raw },
    ];
  }

  get contentTypes() {
    return [
      { text: constants.contentTypes.json, value: constants.contentTypes.json },
      { text: constants.contentTypes.xml, value: constants.contentTypes.xml },
      {
        text: constants.contentTypes.formUrlEncoded,
        value: constants.contentTypes.formUrlEncoded,
      },
      {
        text: constants.contentTypes.soapxml,
        value: constants.contentTypes.soapxml,
      },
      {
        text: constants.contentTypes.textxml,
        value: constants.contentTypes.textxml,
      },
    ];
  }

  get authenticationMethods() {
    return [
      { text: "No authentication", value: AuthenticationMethod.None },
      { text: "Basic Auth", value: AuthenticationMethod.BasicAuth },
      { text: "Api Key", value: AuthenticationMethod.ApiKey },
      { text: "Certificate", value: AuthenticationMethod.Certificate },
      { text: "Bearer Token", value: AuthenticationMethod.BearerToken },
      { text: "Custom Token", value: AuthenticationMethod.CustomToken },
      { text: "Custom Auth", value: constants.authentications.customAuth },
      { text: "Cookie Auth", value: AuthenticationMethod.CookieAuth },
    ];
  }

  get customAuthentications() {
    return [{ text: "Infolog Auth", value: AuthenticationMethod.InfologAuth }];
  }

  get signingMethods() {
    return [
      { text: "None", value: SigningMethod.None },
      { text: "SoapMessage", value: SigningMethod.SoapMessage },
    ];
  }

  get selectMethods() {
    return [
      { text: "All", value: SelectMethod.All },
      { text: "First", value: SelectMethod.First },
      { text: "Last", value: SelectMethod.Last },
    ];
  }

  get stages() {
    return [
      { text: "Test", value: Stage.TEST },
      { text: "PRD", value: Stage.PRD },
    ];
  }

  get grantTypes() {
    return [
      {
        text: constants.grantTypes.clientCredentials,
        value: constants.grantTypes.clientCredentials,
      },
      {
        text: constants.grantTypes.password,
        value: constants.grantTypes.password,
      },
    ];
  }
}

export const lookupService = new LookupService();
