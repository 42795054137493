<template>
  <div>
    <v-card>
      <v-card-text>
        <h4>Bearer token details</h4>
      </v-card-text>
      <div style="padding: 16px; margin-bottom: 32px">
        <v-row>
          <v-col md="4">
            <v-select
              :items="$lookupService.grantTypes"
              v-model="model.grantType"
              label="Grant Type"
              data-cy="granttype"
              @change="onGrantTypeChange"
              :readonly="readonly"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="contentTypes"
              v-model="model.contentType"
              label="Content Type"
              data-cy="content-type"
              :readonly="readonly"
            ></v-select>
          </v-col>
          <v-col md="4">
            <tooltip message="Send credentials using Basic auth scheme">
              <v-checkbox
                v-model="model.encodeCredentialsToBase64"
                label="Encode credentials to base 64"
                data-cy="encode"
                :readonly="readonly"
              ></v-checkbox>
            </tooltip>
          </v-col>
        </v-row>

        <bearer-grant-type-password
          :model="model"
          v-if="isPasswordGrantType"
          :readonly="readonly"
        />

        <bearer-grant-type-client-credentials
          :model="model"
          :readonly="readonly"
          v-if="isClientCredentialsGrantType"
        />

        <!-- additional fields -->
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header data-cy="additional-fields" v-slot="{ open }">
              <div style="display: flex; justify-content: space-between">
                <span> Additional fields </span>
                <span v-if="!open" class="panel-summary"
                  >{{ Object.entries(model.additionalFields).length }} field(s)</span
                >
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <additional-fields-component
                :model="model.additionalFields"
                @onChange="(dict) => (model.additionalFields = dict)"
                :readonly="readonly"
              >
              </additional-fields-component>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import constants from "@/services/constants";
import { BearerCredentialsDto } from "@/datahub-api";
import { Validators } from "@/helpers";
import AdditionalFieldsComponent from "@/components/additional-fields.vue";
import CardWithSwitchComponent from "@/components/card-with-switch.vue";
import BearerGrantTypePassword from "@/views/connection/credentials/bearer-granttype-password.vue";
import BearerGrantTypeClientCredentials from "@/views/connection/credentials/bearer-granttype-client-credentials.vue";

@Component({
  components: {
    AdditionalFieldsComponent,
    CardWithSwitchComponent,
    BearerGrantTypePassword,
    BearerGrantTypeClientCredentials,
  },
})
export default class BearerTokenAuthenticationComponent extends Vue {
  @Prop() model: BearerCredentialsDto;
  @Prop({ default: false }) readonly: boolean;

  rules = {
    additionalField: Validators.Required.Text,
  };

  GrantTypes = {
    Password: constants.grantTypes.password,
    ClientCredentials: constants.grantTypes.clientCredentials,
  };

  get grantType() {
    return this.model.grantType;
  }

  get isPasswordGrantType() {
    return this.grantType === constants.grantTypes.password;
  }

  get isClientCredentialsGrantType() {
    return this.grantType === constants.grantTypes.clientCredentials;
  }

  get contentTypes() {
    return [
      {
        text: constants.contentTypes.formUrlEncoded,
        value: constants.contentTypes.formUrlEncoded,
      },
      {
        text: constants.contentTypes.json,
        value: constants.contentTypes.json,
      },
    ];
  }

  onGrantTypeChange() {
    if (this.grantType == this.GrantTypes.ClientCredentials) {
      this.model.username = null;
      this.model.password = null;
    }
  }
}
</script>
