import { SchemaElementDto } from "@/dataservice-api";

export interface FlattenedSchemaItem {
  path: string;
  elementName?: string;
  element?: SchemaElementDto;
}

function flattenToNodesWithLeavesRecurse(
  path: string | null,
  parent: SchemaElementDto,
  acc: FlattenedSchemaItem[],
  delimiter = "."
) {
  const elementPath = path ? path + delimiter + parent.elementName : parent.elementName;
  parent.schema?.forEach((element) => {
    if (!element.schema && elementPath && !acc.some((x) => x.path === elementPath)) {
      acc.push({
        path: elementPath,
        element: parent,
      });
    } else {
      flattenToNodesWithLeavesRecurse(elementPath, element, acc, delimiter);
    }
  });
}

export function flattenToNodesWithLeaves(root: SchemaElementDto) {
  const result: FlattenedSchemaItem[] = [];
  if (!root) {
    return result;
  }
  flattenToNodesWithLeavesRecurse(null, root, result, "/");
  return result;
}

function flattenRecurse(
  parent: string | null,
  schema: SchemaElementDto[],
  acc: FlattenedSchemaItem[],
  delimiter = "."
) {
  schema.forEach((element) => {
    const path = parent ? parent + delimiter + element.elementName : element.elementName;
    if (!path) return;
    if (element.schema) {
      flattenRecurse(path, element.schema, acc, delimiter);
    } else {
      acc.push({
        path: path,
        elementName: element.elementName,
      });
    }
  });
}

export function flatten(schema: SchemaElementDto[], delimiter = ".") {
  const result: FlattenedSchemaItem[] = [];
  if (!schema) return result;
  flattenRecurse(null, schema, result, delimiter);
  return result;
}
