<template>
    <div>
        <v-data-table
            dense
            data-cy="versioned"
            :headers="headers"
            :items="expressions"
            :items-per-page="10"
        >
            <template v-slot:[`item.id`]="{ item }">
                {{ item.id }}
                <copy :content="item.id"/>
            </template>
            <template v-slot:[`item.createdDate`]="{ item }">
                <span :data-created-date="item.createdDate">{{ item.createdDate | date }}</span>
            </template>
            <template v-slot:[`item.importedDate`]="{ item }">
        <span :data-imported-date="item.importedDate">{{
                item.importedDate | date
            }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <tooltip message="View">
                    <friss-icon
                        data-cy="view"
                        class="mr-3"
                        @click="selectedExpressionId = item.id"
                        name="information"
                    ></friss-icon>
                </tooltip>
                <tooltip message="Delete">
                    <confirm
                        title="Delete version"
                        message="Are you sure to delete the published expression? It might be used in some KBs"
                        yesText="Delete"
                        noText="Cancel"
                        @confirmed="deleteItem(item.id)"
                    >
                        <friss-icon data-cy="deletepbl" name="delete"></friss-icon>
                    </confirm>
                </tooltip>
            </template>
        </v-data-table
        >
        <expression
            v-if="selectedExpressionId !== null"
            :expressionId="selectedExpressionId"
            :isDraft="false"
            @close="selectedExpressionId = null"
        >
        </expression>
    </div>
</template>
<script>
import expression from "@/views/expression/expression.vue";
import tooltip from "@/components/tooltip.vue";
import confirm from "@/components/confirm.dialog.vue";
import copy from "@/components/copy.clipboard.vue";
import {API} from "@/datahub-api";

export default {
    components: {
        expression,
        tooltip,
        copy,
        confirm,
    },
    props: ["expressionCorrelationId"],
    data() {
        return {
            headers: [
                {text: "Id", value: "id"},
                {text: "Created by", value: "createdBy"},
                {text: "Created date", value: "createdDate"},
                {text: "Imported by", value: "importedBy"},
                {text: "Imported date", value: "importedDate"},
                {text: "Actions", value: "actions", sortable: false, align: "right"},
            ],
            expressions: [],
            selectedExpressionId: null,
        };
    },
    async mounted() {
        await this.getAll();
    },
    methods: {
        getAll: async function () {
            const response = await API.expressionService.getHistory(this.expressionCorrelationId);
            if (response.isSuccess) {
                this.expressions = response.result || [];
            }
        },
        async deleteItem(expressionId) {
            const deletionResponse = await API.expressionService.delete(expressionId);

            if (deletionResponse.isFailure) {
                return;
            }

            if (this.expressions.length > 1) {
                await this.getAll();
            } else {
                this.expressions = [];
            }
            this.$emit("onDeleted");
        },
    },
};
</script>
