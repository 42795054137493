<template>
  <v-dialog persistent v-model="showDialog" :max-width="500">
    <v-card class="mx-auto" outlined>
      <v-app-bar>
        <h2>
          <span v-if="!model">Create</span><span v-if="model">Edit</span> certificate
        </h2>
      </v-app-bar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col v-if="!this.dataProviderId" cols="12">
              <v-autocomplete
                :disabled="isEditMode"
                :items="dataProviders"
                v-model="certificateDto.dataProviderId"
                item-text="name"
                item-value="id"
                label="Data Provider"
                :rules="rules.dataProviderId"
                data-cy="certificate-dp"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-file-input
                data-cy="certificate-file"
                label="Certificate file"
                v-model="certificateFile"
                accept=".crt,.pfx"
                @change="onCertificateUpload"
                :rules="rules.certificateFile"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="certificateDto.name"
                data-cy="certificate-name"
                label="Certificate name"
                :rules="rules.name"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                type="password"
                v-model="password"
                data-cy="certificate-password"
                label="Certificate password"
                @input="onPasswordChanged"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn data-cy="cancel" color="secondary" rounded outlined @click="cancel"
          >Cancel</v-btn
        >
        <v-btn
          data-cy="save"
          color="primary"
          rounded
          @click="save"
          v-if="$permissions.canModifyCertificate"
          >Save</v-btn
        >
      </v-card-actions></v-card
    ></v-dialog
  >
</template>
<script>
import { API } from "@/datahub-api";
import { Validators } from "@/helpers";

export default {
  props: {
    model: {
      id: String,
      name: String,
      fileName: String,
      contentBase64: String,
      password: String,
      hasPassword: Boolean,
    },
    dataProviderId: String,
  },
  data() {
    return {
      showDialog: true,
      certificateDto: this.model || {},
      certificateFile: null,
      hasCertificatePreviousValue: false,
      password: null,
      isPasswordChanged: false,
      dataProviders: [],
      rules: {
        dataProviderId: Validators.Required.Guid,
        name: Validators.Required.Text,
        // eslint-disable-next-line vue/no-computed-properties-in-data
        certificateFile: this.isEditMode
          ? []
          : [
              (v) => !!v || "File is required",
              (v) => (v && v.size > 0) || "File is required",
            ],
      },
    };
  },
  methods: {
    onCertificateUpload: function (file) {
      if (!file) {
        this.certificateDto = {
          ...this.certificateDto,
          fileName: "",
          contentBase64: "",
        };
        return;
      }

      const fileReader = new FileReader();

      fileReader.onload = () => {
        var certificateContentBase64 = fileReader.result.split(",")[1];

        this.certificateDto = {
          ...this.certificateDto,
          name: this.certificateDto.name || file.name,
          fileName: file.name,
          contentBase64: certificateContentBase64,
        };
      };

      fileReader.readAsDataURL(file);
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.certificateDto.password = this.isPasswordChanged ? this.password : null;

      let response = null;
      if (this.model) {
        response = await API.certificateService.update(this.certificateDto);
      } else {
        response = await API.certificateService.create(this.certificateDto);
      }

      if (response.isSuccess) {
        this.$emit("saved");
        this.$emit("close");
      }
    },
    cancel() {
      this.$emit("close");
    },
    onPasswordChanged() {
      this.isPasswordChanged = true;
    },
  },
  async mounted() {
    if (this.dataProviderId) {
      this.certificateDto.dataProviderId = this.dataProviderId;
    } else {
      let response = await API.dataProviderService.getAll();
      this.dataProviders = response.result;
    }

    if (this.isEditMode) {
      this.certificateFile = { size: 1, name: this.certificateDto.fileName };
      this.password = this.certificateDto.hasPassword ? "*******" : null;
    } else {
      this.certificateFile = null;
      this.password = null;
    }
  },
  computed: {
    isEditMode() {
      return !!this.certificateDto.id;
    },
  },
};
</script>
