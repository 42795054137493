var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('card-with-switch-component',{attrs:{"isOpen":_vm.model.asyncOptions,"title":"Is asynchronous","hint":"Enable asynchronous behavior","disabled":_vm.model.responseType === _vm.ResponseType.Raw,"data-cy":"asyncEnabled"},on:{"change":_vm.onAsyncEnabledChange}},[(_vm.model.asyncOptions)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"timeout",attrs:{"id":"panelAsyncTimeout","data-cy":"timeout","value":_vm._f("secondsTo")(_vm.model.asyncOptions.timeout,'minutes'),"label":"Timeout (minutes)","type":"number","rules":_vm.rules.timeout,"hint":"The amount of time after which the background polling will be stopped","persistent-hint":""},on:{"input":(value) => {
            _vm.model.asyncOptions.timeout = _vm.convert.minutesTo(value, 'seconds');
            _vm.validateRetryTimespans();
          }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"interval",attrs:{"id":"panelAsyncInterval","data-cy":"interval","value":_vm._f("secondsTo")(_vm.model.asyncOptions.interval,'minutes'),"type":"number","label":"Interval (minutes)","rules":_vm.rules.interval,"hint":"The time to wait between each background poll","persistent-hint":""},on:{"input":(value) => {
            _vm.model.asyncOptions.interval = _vm.convert.minutesTo(value, 'seconds');
            _vm.validateRetryTimespans();
          }}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }